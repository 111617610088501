// ClipsManagePosts.js

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../utils/firebaseConfig';
import './ManagePosts.css';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';

// 1) Import the react-query "useQueryClient" hook:
import { useQueryClient } from 'react-query';

const ClipsManagePosts = () => {
  const {
    posts: globalPosts,
    deletePostById,
    updateSinglePost,
    fetchMorePosts,
    loading,
    hasMore
  } = useContext(ClipsPostsContext);

  // 2) Create a queryClient instance so we can invalidate 'homePagePosts':
  const queryClient = useQueryClient();

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Only show "active" (not deleted) posts
    setPosts(globalPosts.filter(post => !post.deleted));
  }, [globalPosts]);

  // Infinite scroll to fetch older posts
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 200 &&
        !loading &&
        hasMore
      ) {
        fetchMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, fetchMorePosts]);

  // Called when "Delete" is pressed
  const handleDelete = async (postId) => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      await deletePostById(postId);
    }
  };

  // Called when "Show on Home Page" is toggled
  const handleShowOnHomePageChange = async (post, showOnHomePage) => {
    const postRef = doc(db, "clipsPosts", post.id);
    // 3) Update in Firestore
    await updateDoc(postRef, { showOnHomePage });
    // 4) Update in our global `posts` array (so immediate local reflection)
    updateSinglePost({ ...post, showOnHomePage });
    // 5) Invalidate the React Query to re-fetch 'homePagePosts' in HomePage.js
    queryClient.invalidateQueries('homePagePosts');
  };

  // Called when "Show in Discover Posts Box" is toggled
  const handleShowInDiscoverBoxChange = async (post, showInDiscoverBox) => {
    const postRef = doc(db, "clipsPosts", post.id);
    await updateDoc(postRef, { showInDiscoverBox });
    updateSinglePost({ ...post, showInDiscoverBox });
    // Notice: Not invalidating 'homePagePosts' here, 
    // since that only depends on showOnHomePage
    // If you'd like to also refresh something else for discover, you could do so.
  };

  return (
    <div>
      <h2>Manage Posts</h2>
      <div>Total Posts: {posts.length}</div>
      <ul className="post-list">
        {posts.map(post => (
          <li key={post.id} className="post-item">
            {post.imageUrl && (
              <img
                src={post.imageUrl}
                alt={post.title || "Post image"}
                className="post-thumbnail"
              />
            )}
            <Link to={`/clipsposts/${post.id}`} className="post-title">
              {post.title}
            </Link>

            <div>
              <label>
                <input
                  type="checkbox"
                  checked={post.showOnHomePage || false}
                  onChange={(e) =>
                    handleShowOnHomePageChange(post, e.target.checked)
                  }
                />
                Show on Home Page
              </label>
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  checked={post.showInDiscoverBox || false}
                  onChange={(e) =>
                    handleShowInDiscoverBoxChange(post, e.target.checked)
                  }
                />
                Show in Discover Posts Box
              </label>
            </div>

            <button onClick={() => handleDelete(post.id)} className="button-delete">
              Delete
            </button>

            {/* Displaying the Post Summary, Share Profile Name, and full Share Profile Brief Bio */}
            <div className="info-row">
              <div>
                <strong>Post Summary:</strong>{" "}
                {post.summary ||
                  (post.description.length > 100
                    ? `${post.description.substring(0, 100)}...`
                    : post.description)}
              </div>
              <div><strong>Name:</strong> {post.userName || post.author}</div>
              {post.bio && (
                <div>
                  <strong>Brief Bio:</strong> {post.bio}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>

      {loading && <p>Loading more posts...</p>}
    </div>
  );
};

export default ClipsManagePosts;
