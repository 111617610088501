// ClipsPostForm.js

import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  collection,
  addDoc,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { useAuth } from '../contexts/AuthContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoMdCloudUpload, IoMdImages } from 'react-icons/io';
import {
  FaInstagram,
  FaTwitter,
  FaTiktok,
  FaYoutube,
  FaFacebook
} from 'react-icons/fa';
import CloseButton from './CloseButton';
import {
  uploadImageToStorage,
  savePostSummary,
  checkFileValidity as originalValidateFileType
} from '../utils/dbUtilities';
import heic2any from 'heic2any';
import './PostForm.css';
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';
import axios from 'axios';

// =========== Constants & Utility Functions ===========

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const invalidFileTypes = ['video/mp4', 'audio/wav', 'image/gif', 'video/x-ms-wmv'];

// Slightly modified checkFileValidity so it can be used by both main & profile image dropzones
const checkFileValidity = (file, setError, setProcessing, setProgress) => {
  if (invalidFileTypes.includes(file.type)) {
    const errorMsg = 'Invalid file type. MP4, WAV, GIF, and WMV files are not allowed.';
    setError(errorMsg);
    setProcessing(false);
    setProgress(0);
    return false;
  }

  if (file.size > MAX_FILE_SIZE) {
    const errorMsg = 'File size exceeds 5 MB limit.';
    setError(errorMsg);
    setProcessing(false);
    setProgress(0);
    return false;
  }

  setError('');
  return true;
};

const compressImage = (file, setProgress) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob((blob) => {
        setProgress(70);
        resolve(blob);
      }, 'image/jpeg', 0.5);
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
};

const convertImageToJPG = async (file, setProgress) => {
  if (file.type === 'image/heic') {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.92
      });
      setProgress(50);
      return convertedBlob;
    } catch (error) {
      console.error('Error converting image:', error);
      throw error;
    }
  } else {
    return compressImage(file, setProgress);
  }
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop, circular = false) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  if (circular) {
    ctx.globalCompositeOperation = 'destination-in';
    ctx.beginPath();
    ctx.arc(
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2,
      0,
      2 * Math.PI,
      true
    );
    ctx.fill();
  }

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
};

// =========== Dropzone Components ===========
function MyDropzone({
  onImageUpload,
  setIsImageProcessing,
  setProgress,
  setUploadError,
  onImageChosen,
  onCropperOpen
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    // If the cropper is open (selectedImage && no croppedImagePreview), inform parent
    if (selectedImage && !croppedImagePreview) {
      onCropperOpen(true);
    } else {
      onCropperOpen(false);
    }
  }, [selectedImage, croppedImagePreview, onCropperOpen]);

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImage);

      setCroppedImagePreview(croppedImageUrl);
      setSelectedImage(null);

      // Pass final cropped image blob to parent
      onImageUpload(croppedImage);

      // Mark image processing as done
      setIsImageProcessing(false);
      setProgress(100);
    } catch (error) {
      console.error('Error cropping image:', error);
      setIsImageProcessing(false);
      setProgress(0);
    }
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setCroppedImagePreview(null);
    setOriginalImage(null);
    onImageChosen(false);

    // Also mark image processing as done if user cancels
    setIsImageProcessing(false);
    setProgress(0);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setSelectedImage(originalImage);
    setCroppedImagePreview(null);
  };

  const onDrop = async (acceptedFiles) => {
    setIsImageProcessing(true);
    setProgress(5);

    const file = acceptedFiles[0];
    if (!checkFileValidity(file, setUploadError, setIsImageProcessing, setProgress)) {
      return;
    }

    onImageChosen(true);

    try {
      const imageBlob =
        file.type === 'image/heic'
          ? await convertImageToJPG(file, setProgress)
          : await compressImage(file, setProgress);
      const imageUrl = URL.createObjectURL(imageBlob);

      setOriginalImage(imageUrl);
      setSelectedImage(imageUrl);
      setCroppedImagePreview(null);
      setProgress(80);
    } catch (error) {
      console.error('Error in onDrop image conversion:', error);
      // Also reset if there's an error
      setIsImageProcessing(false);
      setProgress(0);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  });

  return (
    <div className="image-upload-section">
      {!selectedImage && !croppedImagePreview && (
        <div {...getRootProps()} className="drag-drop-zone">
          <input {...getInputProps()} />
          <IoMdCloudUpload size={50} className="upload-icon" />
          <p>Drag & Drop or Click Here to Upload Image (5 MB Size Limit)</p>
          <IoMdImages size={50} className="images-icon" />
        </div>
      )}

      {croppedImagePreview && (
        <div className="image-preview-container">
          <img
            src={croppedImagePreview}
            alt="Cropped"
            className="uploaded-image-preview"
          />
          <button onClick={handleCancel} className="remove-image-icon">
            X
          </button>
          <button onClick={handleEdit} className="edit-button">
            Edit
          </button>
        </div>
      )}

      {selectedImage && !croppedImagePreview && (
        <div className="cropper-container">
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="rect"
            showGrid={false}
            style={{
              containerStyle: {
                position: 'relative',
                width: '100%',
                height: '400px'
              },
              cropAreaStyle: {
                border: '2px solid white'
              },
              mediaStyle: {
                transform: 'translateZ(0)'
              }
            }}
          />
          <div className="cropper-toolbar">
            <button type="button" onClick={handleSave} className="save-button">
              Save
            </button>
            <button type="button" onClick={handleCancel} className="cancel-button">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function ProfileImageDropzone({
  onImageUpload,
  setIsImageProcessing,
  setProgress,
  setUploadError
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    const savedProfileImageUrl = localStorage.getItem('profileImageUrl');
    if (savedProfileImageUrl) {
      setCroppedImagePreview(savedProfileImageUrl);
    }
  }, []);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels, true);
      const croppedImageUrl = URL.createObjectURL(croppedImage);

      setCroppedImagePreview(croppedImageUrl);
      setSelectedImage(null);

      // Give final cropped blob to parent
      onImageUpload(croppedImage);

      // Mark done
      setIsImageProcessing(false);
      setProgress(100);
    } catch (error) {
      console.error('Error cropping image:', error);
      setIsImageProcessing(false);
      setProgress(0);
    }
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setCroppedImagePreview(null);

    // Also reset if canceled
    setIsImageProcessing(false);
    setProgress(0);
  };

  const handleRemove = () => {
    setSelectedImage(null);
    setCroppedImagePreview(null);
    onImageUpload(null);
    localStorage.removeItem('profileImageUrl');

    // Also reset if removed
    setIsImageProcessing(false);
    setProgress(0);
  };

  const onDrop = async (acceptedFiles) => {
    setIsImageProcessing(true);
    setProgress(5);

    const file = acceptedFiles[0];
    if (!checkFileValidity(file, setUploadError, setIsImageProcessing, setProgress)) {
      return;
    }

    try {
      const imageBlob =
        file.type === 'image/heic'
          ? await convertImageToJPG(file, setProgress)
          : await compressImage(file, setProgress);
      const imageUrl = URL.createObjectURL(imageBlob);

      setSelectedImage(imageUrl);
      setCroppedImagePreview(null);
      setProgress(80);
    } catch (error) {
      console.error('Error in onDrop image conversion (Profile):', error);
      setIsImageProcessing(false);
      setProgress(0);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  });

  return (
    <div className="image-upload-section profile-image">
      {!selectedImage && !croppedImagePreview && (
        <div {...getRootProps()} className="drag-drop-zone circular">
          <input {...getInputProps()} />
          <IoMdCloudUpload size={50} className="upload-icon" />
          <p>Upload Profile Image (5 MB Size Limit)</p>
          <IoMdImages size={50} className="images-icon" />
        </div>
      )}

      {croppedImagePreview && (
        <div className="image-preview-container circular">
          <img
            src={croppedImagePreview}
            alt="Cropped"
            className="uploaded-image-preview"
          />
          <button onClick={handleRemove} className="remove-image-icon">
            X
          </button>
        </div>
      )}

      {selectedImage && !croppedImagePreview && (
        <div className="cropper-wrapper circular">
          <div className="cropper-container circular">
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
              showGrid={false}
              style={{
                containerStyle: {
                  width: '100%',
                  height: '300px',
                  position: 'relative'
                },
                cropAreaStyle: {
                  border: '2px solid white',
                  borderRadius: '50%'
                },
                mediaStyle: {
                  transform: 'translateZ(0)'
                }
              }}
            />
          </div>
          <div className="cropper-toolbar">
            <button type="button" onClick={handleSave} className="save-button">
              Save
            </button>
            <button type="button" onClick={handleCancel} className="cancel-button">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

// =========== Main Component ===========
const ClipsPostForm = () => {
  const { currentUser, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Main image states
  const [uploadedImageBlob, setUploadedImageBlob] = useState(null);
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState('');

  // Form fields
  const [nickname, setNickname] = useState('');
  const [description, setDescription] = useState('');
  const [city, setCity] = useState('');
  const [stateVal, setStateVal] = useState('');
  const [country, setCountry] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [link, setLink] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [youtube, setYoutube] = useState('');
  const [facebook, setFacebook] = useState('');
  const [bio, setBio] = useState('');
  const [email, setEmail] = useState('');

  // Word counts
  const [wordCount, setWordCount] = useState(0);
  const [bioWordCount, setBioWordCount] = useState(0);

  // Validation errors
  const [fieldErrors, setFieldErrors] = useState({});
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  // Categories & goals
  const [categories, setCategories] = useState([]);
  const [goals, setGoals] = useState([]);

  // Profile image
  const [profileImageBlob, setProfileImageBlob] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [profileImageError, setProfileImageError] = useState('');
  const [profileImageProgress, setProfileImageProgress] = useState(0);
  const [profileImageProcessing, setProfileImageProcessing] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState('');

  // CHANGED: `Your Name` is now required
  const [userName, setUserName] = useState('');

  // For "image chosen but not saved"
  const [imageChosen, setImageChosen] = useState(false);
  const [imageNotSavedAlert, setImageNotSavedAlert] = useState(false);

  // Track if cropper is open
  const [isCropperOpen, setIsCropperOpen] = useState(false);

  // A "top-level" general error message
  const [generalError, setGeneralError] = useState('');

  // Possibly get suggestion text from location.state
  const suggestionText = location.state?.suggestionText || '';
  const generatedBio = location.state?.generatedBio || '';

  // Hooks/contexts
  const { addNewPost, updateSinglePost } = useContext(ClipsPostsContext);

  // ===== Redirect if not logged in =====
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  // Word Count Helpers
  const updateWordCount = (text) => {
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
  };

  const updateBioWordCount = (text) => {
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    setBioWordCount(words.length);
  };

  // Simple email validation
  const isValidEmail = (emailStr) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailStr).toLowerCase());
  };

  // Validate user-provided file type
  const validateFileType = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];
    if (!validTypes.includes(file.type)) {
      setUploadError('Invalid file type. Only JPEG, PNG, HEIC, and HEIF images are allowed.');
      return false;
    }
    return true;
  };

  // Goals Setup
  useEffect(() => {
    const storedGoals = JSON.parse(localStorage.getItem('goals')) || [];
    setGoals(storedGoals);
  }, []);

  useEffect(() => {
    localStorage.setItem('goals', JSON.stringify(goals));
  }, [goals]);

  // ======== Handle Submit ========
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setShowErrors(true);

    // Clear any existing top-level error
    setGeneralError('');

    // If user selected an image but never saved it
    if (imageChosen && !uploadedImageBlob) {
      setImageNotSavedAlert(true);
      setIsSubmitting(false);
      return;
    } else {
      setImageNotSavedAlert(false);
    }

    try {
      // Gather form field values
      const form = event.target;
      const nicknameVal = form['nickname'].value.trim();
      const descriptionVal = form['description'].value.trim();
      const cityVal = form['city'].value.trim();
      const stateVal2 = form['state'].value.trim();
      const countryVal = form['country'].value.trim();
      const locationValue = form['location'].value;
      const linkVal = form['link'].value.trim();
      const instagramVal = form['instagram'].value.trim();
      const twitterVal = form['twitter'].value.trim();
      const tiktokVal = form['tiktok'].value.trim();
      const youtubeVal = form['youtube'].value.trim();
      const facebookVal = form['facebook'].value.trim();

      // CHANGED: userName & bio are now required
      const userNameVal = form['userName']?.value.trim();
      const bioVal = form['bio']?.value.trim();

      const emailVal = form['email'].value.trim();

      let hasErrors = false;
      const newFieldErrors = {};

      // Basic required checks
      if (!nicknameVal) {
        newFieldErrors.nickname = 'Name is required.';
        hasErrors = true;
      }
      if (!descriptionVal) {
        newFieldErrors.description = 'Description is required.';
        hasErrors = true;
      }
      if (categories.length === 0) {
        newFieldErrors.categories = 'Please select at least one category.';
        hasErrors = true;
      }
      if (goals.length === 0) {
        newFieldErrors.goals = 'Please select at least one goal.';
        hasErrors = true;
      }
      if (!locationValue) {
        newFieldErrors.location = 'Please select a location.';
        hasErrors = true;
      }
      if (!cityVal) {
        newFieldErrors.city = 'City is required.';
        hasErrors = true;
      }
      if (!countryVal) {
        newFieldErrors.country = 'Country is required.';
        hasErrors = true;
      }
      if (wordCount > 40) {
        newFieldErrors.description = 'Description cannot exceed 40 words.';
        hasErrors = true;
      }

      // CHANGED: "Your Name" must not be empty
      if (!userNameVal) {
        newFieldErrors.userName = 'Your Name is required.';
        hasErrors = true;
      }

      // CHANGED: "Brief Bio" must not be empty
      if (!bioVal) {
        newFieldErrors.bio = 'Brief Bio is required.';
        hasErrors = true;
      }
      // Also still enforce max 40 words for bio
      if (bioVal && bioWordCount > 40) {
        newFieldErrors.bio = 'Bio cannot exceed 40 words.';
        hasErrors = true;
      }

      if (linkVal && !/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(linkVal)) {
        newFieldErrors.link = 'Please enter a valid URL';
        hasErrors = true;
      }
      if (emailVal && !isValidEmail(emailVal)) {
        newFieldErrors.email = 'Please enter a valid email address.';
        hasErrors = true;
      }

      setFieldErrors(newFieldErrors);

      // If there are validation errors, set top-level error
      if (hasErrors) {
        setGeneralError('One or more required fields are missing or invalid.');
        setIsSubmitting(false);
        return;
      } else {
        setGeneralError('');
      }

      // If an image was uploaded, confirm valid file type
      if (uploadedImageBlob && !validateFileType(uploadedImageBlob)) {
        setIsSubmitting(false);
        return;
      }

      // Upload the main image if provided
      let imageDownloadUrl = null;
      if (uploadedImageBlob) {
        imageDownloadUrl = await uploadImageToStorage(
          uploadedImageBlob,
          `images/${currentUser.uid}_${new Date().toISOString()}`
        );
      }

      // Determine final profile image URL (from local state or localStorage)
      const finalProfileImageUrl =
        profileImagePreview || profileImageUrl || localStorage.getItem('profileImageUrl') || '';

      // Build new post data
      const newPostData = {
        userId: currentUser.uid,
        title: nicknameVal,
        description: descriptionVal,
        city: cityVal,
        state: stateVal2,
        country: countryVal,
        location: locationValue,
        categories,
        goals,
        link: linkVal,
        instagram: instagramVal,
        twitter: twitterVal,
        tiktok: tiktokVal,
        youtube: youtubeVal,
        facebook: facebookVal,

        // CHANGED: "userName" and "bio" are now required fields
        userName: userNameVal,
        bio: bioVal,

        profileImageUrl: finalProfileImageUrl,
        author: currentUser.displayName || 'Anonymous',
        upvotes: 0,
        timestamp: serverTimestamp(),
        imageUrl: imageDownloadUrl,
        email: emailVal || null,
        viewCount: 0,
        linkClickCount: 0,
        impressionCount: 0,
        instagramClickCount: 0,
        twitterClickCount: 0,
        tiktokClickCount: 0,
        youtubeClickCount: 0,
        facebookClickCount: 0,
        discoveryBoxImpressionCount: 0
      };

      // Add doc in Firestore
      const docRef = await addDoc(collection(db, 'clipsPosts'), newPostData);
      const optimisticPost = { ...newPostData, id: docRef.id, timestamp: new Date() };
      addNewPost(optimisticPost);

      // Attempt to generate summary (but don't block if it fails)
      await generateSummary(
        descriptionVal,
        docRef,
        setError,
        navigate,
        resetForm,
        optimisticPost,
        setIsSubmitting,
        updateSinglePost
      );
    } catch (error) {
      console.error('Error saving post:', error);
      setError('Error creating post. Please try again.');
      setIsSubmitting(false);
    }
  };

  // ===== Summaries / AI call =====
  const generateSummary = async (
    descriptionVal,
    docRef,
    setError,
    navigate,
    resetForm,
    optimisticPost,
    setIsSubmitting,
    updateSinglePost
  ) => {
    const apiKey = process.env.REACT_APP_OPENAI_KEY;
    if (!apiKey) {
      console.log('OpenAI API key not found');
      // If there's no API key, just finalize without summary
      return finalizePost();
    }

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [
            {
              role: 'system',
              content:
                'You are an assistant that generates concise summaries that closely resemble the original text.'
            },
            {
              role: 'user',
              content: `Summarize the following text in 8-10 words as a complete sentence, capturing the essence of the original text:\n\n${descriptionVal}`
            }
          ],
          max_tokens: 20,
          temperature: 0.7,
          n: 1,
          stop: ['\n']
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data?.choices?.[0]) {
        const generatedSummary = response.data.choices[0].message.content.trim();
        // Save summary in Firestore
        await savePostSummary(docRef.id, generatedSummary);

        // Update local state
        updateSinglePost({
          ...optimisticPost,
          summary: generatedSummary
        });
      }

      finalizePost();
    } catch (error) {
      console.error('Error generating post summary:', error);
      // Show a soft error, but do not block post creation
      setError(
        'Unable to generate a summary at this time, but your post was created successfully.'
      );
      finalizePost();
    }

    function finalizePost() {
      setIsSubmitting(false);
      localStorage.removeItem('description');
      localStorage.removeItem('nickname');
      localStorage.removeItem('categories');

      resetForm();
      navigate(`/clipsposts/${docRef.id}`);
    }
  };

  // ===== Reset Form =====
  const resetForm = () => {
    setNickname('');
    setDescription('');
    setCity('');
    setStateVal('');
    setCountry('');
    setUserLocation('');
    setLink('');
    setInstagram('');
    setTwitter('');
    setTiktok('');
    setYoutube('');
    setFacebook('');
    setCategories([]);
    setGoals([]);
    setUserName('');
    setEmail('');
    setBio('');
    setUploadedImageBlob(null);
    setProfileImageBlob(null);
    setProfileImagePreview(null);
    setImageChosen(false);
    setIsSubmitting(false);
  };

  const handleClose = () => {
    resetForm();
    navigate(-1);
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className="form-container" style={{ marginTop: '150px', backgroundColor: 'white' }}>
      <CloseButton onClick={handleClose} />
      <div className="form-header">
        <h2 className="form-title" style={{ color: 'black' }}>
          New Post
        </h2>
      </div>

      <form className="post-form" onSubmit={handleSubmit}>
        {/* ====== Name / Nickname ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }} title="Give your post a unique name or title">
            What Are You Sharing With Us?
          </label>
          <input
            type="text"
            name="nickname"
            className={`form-input ${showErrors && fieldErrors.nickname ? 'error' : ''}`}
            placeholder="The Name of what You're Sharing. Ideas are Welcome Too!"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
          {showErrors && fieldErrors.nickname && (
            <p className="error-message">{fieldErrors.nickname}</p>
          )}
        </div>

        {/* ====== Description ====== */}
        <div className="form-group">
          <label
            style={{ color: 'black' }}
            title="Briefly tell us the story behind what you're sharing"
          >
            Tell Us What It's All About (Need Help?{' '}
            <a
              href="https://chatgpt.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#1a0dab', textDecoration: 'none', cursor: 'pointer' }}
            >
              Use ChatGPT!
            </a>)
          </label>
          <textarea
            name="description"
            className={`form-input ${showErrors && fieldErrors.description ? 'error' : ''}`}
            rows="4"
            placeholder="Briefly describe what you're sharing (up to 40 words)."
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              updateWordCount(e.target.value);
            }}
          />
          <p className={`word-count ${wordCount > 40 ? 'word-count-exceeded' : ''}`}>
            {wordCount} / 40 words
          </p>
          {showErrors && fieldErrors.description && (
            <p className="error-message">{fieldErrors.description}</p>
          )}

          <p
            style={{
              color: '#666',
              fontSize: '0.9rem',
              marginTop: '8px',
              marginBottom: '16px',
              fontStyle: 'italic'
            }}
          >
            Your Post Description will also be used by AI to Promote your Post across HyroClipse.
          </p>
        </div>

        {/* ====== Categories ====== */}
        <div className="form-group category-group">
          <label style={{ color: 'black' }} title="Select a category that best fits your post">
            Select a Category (at least 1)
          </label>
          <div className="form-options">
            {[
              'Animals',
              'Arts',
              'Beauty & Personal Care',
              'Charity',
              'Children',
              'Collectors',
              'Comedy',
              'Coming Soon',
              'Commmunity Outreach',
              'Community Organizing',
              'Education',
              'Entertainment',
              'Events',
              'Exercise & Fitness',
              'Fashion & Clothing',
              'Food',
              'FYI',
              'Gaming',
              'Health',
              'Home & Living',
              'Idea',
              'Launch',
              'Media',
              'Money & Finance',
              'Music',
              'New Project',
              'New Business',
              'News & Current Events',
              'Productivity',
              'Social',
              'Social Media',
              'Spiritual',
              'Sports',
              'Startups',
              'Tech',
              'Test Run',
              'Toys',
              'Travel',
              'TV & Film',
              'Vintage',
              'Other'
            ].map((category) => (
              <div key={category}>
                <input
                  type="checkbox"
                  id={category.toLowerCase()}
                  name="category"
                  value={category}
                  checked={categories.includes(category)}
                  onChange={(e) => {
                    const { value, checked } = e.target;
                    if (checked) {
                      setCategories((prev) => [...prev, value]);
                    } else {
                      setCategories((prev) => prev.filter((cat) => cat !== value));
                    }
                  }}
                />
                <label htmlFor={category.toLowerCase()}>{category}</label>
              </div>
            ))}
          </div>

          {showErrors && fieldErrors.categories && (
            <p className="error-message">{fieldErrors.categories}</p>
          )}
        </div>

        {/* ====== Goals ====== */}
        <div className="form-group goals-group">
          <label style={{ color: 'black' }}>Select Your Goal(s)</label>
          <div className="form-options">
            {[
              'Build Awareness',
              'Gain New Followers',
              'Constructive Feedback',
              'Increase Sales',
              'Going Viral',
              'Fundraising'
            ].map((goal) => (
              <div key={goal}>
                <input
                  type="checkbox"
                  id={goal.toLowerCase().replace(/\s+/g, '-')}
                  name="goals"
                  value={goal}
                  checked={goals.includes(goal)}
                  onChange={(e) => {
                    const { value, checked } = e.target;
                    if (checked) {
                      setGoals((prev) => [...prev, value]);
                    } else {
                      setGoals((prev) => prev.filter((g) => g !== value));
                    }
                  }}
                />
                <label htmlFor={goal.toLowerCase().replace(/\s+/g, '-')}>{goal}</label>
              </div>
            ))}
          </div>

          {showErrors && fieldErrors.goals && (
            <p className="error-message">{fieldErrors.goals}</p>
          )}
        </div>

        {/* ====== Link (Optional) ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }} title="Add a link to your website">
            Add a URL Link (Optional)
          </label>
          <input
            type="text"
            name="link"
            className={`form-input ${showErrors && fieldErrors.link ? 'error' : ''}`}
            placeholder="e.g., example.com"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          {showErrors && fieldErrors.link && (
            <p className="error-message">{fieldErrors.link}</p>
          )}
        </div>

        {/* ====== Email (Optional) ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }} title="Provide a contact email (optional)">
            Add Your Contact Email (Optional)
          </label>
          <input
            type="email"
            name="email"
            className={`form-input ${showErrors && fieldErrors.email ? 'error' : ''}`}
            placeholder="e.g., your@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {showErrors && fieldErrors.email && (
            <p className="error-message">{fieldErrors.email}</p>
          )}
        </div>

        {/* ====== Socials (Optional) ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }}>Share Your Socials (Optional)</label>
          <div className="social-inputs">
            <div className="social-input">
              <FaInstagram className="social-icon instagram" />
              <input
                type="text"
                name="instagram"
                className="form-input"
                placeholder="Instagram Username"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
            </div>
            <div className="social-input">
              <FaTwitter className="social-icon twitter" />
              <input
                type="text"
                name="twitter"
                className="form-input"
                placeholder="Twitter/X Username"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </div>
            <div className="social-input">
              <FaTiktok className="social-icon tiktok" />
              <input
                type="text"
                name="tiktok"
                className="form-input"
                placeholder="TikTok Username"
                value={tiktok}
                onChange={(e) => setTiktok(e.target.value)}
              />
            </div>
            <div className="social-input">
              <FaYoutube className="social-icon youtube" />
              <input
                type="text"
                name="youtube"
                className="form-input"
                placeholder="YouTube Channel Handle"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
              />
            </div>
            <div className="social-input">
              <FaFacebook className="social-icon facebook" />
              <input
                type="text"
                name="facebook"
                className="form-input"
                placeholder="Facebook Username"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* ====== Location ====== */}
        <div className="form-group location-group">
          <label style={{ color: 'black' }}>Where Are You Based?</label>
          <div className="form-options">
            {[
              'North America',
              'Europe',
              'Africa',
              'Middle East',
              'Asia',
              'Central America',
              'South America',
              'Australia'
            ].map((loc) => (
              <div key={loc}>
                <input
                  type="radio"
                  id={loc.toLowerCase().replace(' ', '')}
                  name="location"
                  value={loc}
                  checked={userLocation === loc}
                  onChange={(e) => setUserLocation(e.target.value)}
                />
                <label htmlFor={loc.toLowerCase().replace(' ', '')}>{loc}</label>
              </div>
            ))}
          </div>
          {showErrors && fieldErrors.location && (
            <p className="error-message">{fieldErrors.location}</p>
          )}
        </div>

        {/* ====== City ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }}>City</label>
          <input
            type="text"
            name="city"
            className={`form-input ${showErrors && fieldErrors.city ? 'error' : ''}`}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          {showErrors && fieldErrors.city && (
            <p className="error-message">{fieldErrors.city}</p>
          )}
        </div>

        {/* ====== State (Optional) ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }}>State (Optional)</label>
          <input
            type="text"
            name="state"
            className="form-input"
            value={stateVal}
            onChange={(e) => setStateVal(e.target.value)}
          />
        </div>

        {/* ====== Country ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }}>Country</label>
          <input
            type="text"
            name="country"
            className={`form-input ${showErrors && fieldErrors.country ? 'error' : ''}`}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          {showErrors && fieldErrors.country && (
            <p className="error-message">{fieldErrors.country}</p>
          )}
        </div>

        {/* ====== Main Image Dropzone ====== */}
        <MyDropzone
          onImageUpload={(imgBlob) => setUploadedImageBlob(imgBlob)}
          setIsImageProcessing={setIsImageProcessing}
          setProgress={setProgress}
          setUploadError={setUploadError}
          onImageChosen={setImageChosen}
          onCropperOpen={setIsCropperOpen}
        />
        {uploadError && <p className="error-message">{uploadError}</p>}
        {isImageProcessing && (
          <div className="progress-bar-container">
            <progress value={progress} max="100"></progress>
          </div>
        )}

        {/* ====== Your Name (MANDATORY) ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }}>Your Name</label>
          <input
            type="text"
            name="userName"
            className={`form-input ${showErrors && fieldErrors.userName ? 'error' : ''}`}
            placeholder="Enter your name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          {showErrors && fieldErrors.userName && (
            <p className="error-message">{fieldErrors.userName}</p>
          )}
        </div>

        {/* ====== Bio (MANDATORY) ====== */}
        <div className="form-group">
          <label style={{ color: 'black' }}>
            Brief Bio (Need Help?{' '}
            <a
              href="https://chatgpt.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#1a0dab', textDecoration: 'none', cursor: 'pointer' }}
            >
              Use ChatGPT!
            </a>)
          </label>
          <textarea
            name="bio"
            className={`form-input ${showErrors && fieldErrors.bio ? 'error' : ''}`}
            rows="4"
            placeholder="Enter a brief bio (max 40 words)"
            value={bio}
            onChange={(e) => {
              setBio(e.target.value);
              updateBioWordCount(e.target.value);
            }}
          />
          <p className={`word-count ${bioWordCount > 40 ? 'word-count-exceeded' : ''}`}>
            {bioWordCount} / 40 words
          </p>
          {showErrors && fieldErrors.bio && (
            <p className="error-message">{fieldErrors.bio}</p>
          )}
        </div>

        {/* ====== Profile Image Section ====== */}
        <h3 style={{ color: 'black', marginBottom: '8px' }}>Profile Image</h3>
        <ProfileImageDropzone
          onImageUpload={(imgBlob) => setProfileImageBlob(imgBlob)}
          setIsImageProcessing={setProfileImageProcessing}
          setProgress={setProfileImageProgress}
          setUploadError={setProfileImageError}
        />
        {profileImageError && <p className="error-message">{profileImageError}</p>}
        {profileImageProcessing && (
          <div className="progress-bar-container">
            <progress value={profileImageProgress} max="100"></progress>
          </div>
        )}
        <p
          style={{
            marginTop: '16px',
            fontSize: '0.95rem',
            color: '#555',
            fontStyle: 'italic',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          Add a Photo of You to Boost Post Views!
        </p>

        {imageNotSavedAlert && (
          <p className="error-message" style={{ textAlign: 'center', marginTop: '20px' }}>
            Image Upload not Saved
          </p>
        )}

        {/* ====== The NEW general error block (above Post button) ====== */}
        {generalError && (
          <p className="error-message" style={{ textAlign: 'center', marginTop: '20px' }}>
            {generalError}
          </p>
        )}

        {/* ====== Post Button (hide if cropper is open) ====== */}
        {!isCropperOpen && (
          <div className="form-footer">
            <button
              type="submit"
              className={`form-button ${isImageProcessing || isSubmitting ? 'disabled' : ''}`}
              disabled={isImageProcessing || isSubmitting}
            >
              {isSubmitting ? 'Posting...' : 'Post'}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ClipsPostForm;
