// ClipsSearchResultsPage.js

import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { useAuth } from '../contexts/AuthContext';
import VisibilityTracker from './VisibilityTracker';
import './HomePage.css';

const ClipsSearchResultsPage = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('query');
  const { posts, deletePostById } = useContext(ClipsPostsContext);
  const { currentUser } = useAuth();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery) {
      document.title = `Search results for '${searchQuery}'`;
    } else {
      document.title = 'Search results';
    }
  }, [searchQuery]);

  useEffect(() => {
    const filterPosts = () => {
      setIsLoading(true);
      setError(null);

      try {
        const queryLower = searchQuery ? searchQuery.toLowerCase() : '';
        const filteredResults = posts.filter(post =>
          post.title.toLowerCase().includes(queryLower) ||
          post.author.toLowerCase().includes(queryLower) ||
          (post.description && post.description.toLowerCase().includes(queryLower))
        );

        setResults(filteredResults);
      } catch (err) {
        setError('Error filtering search results');
      } finally {
        setIsLoading(false);
      }
    };

    if (searchQuery) {
      filterPosts();
    }
  }, [searchQuery, posts]);

  // 1) Deletion approach
  const handleDelete = async (postId) => {
    const confirm = window.confirm('Are you sure you want to delete this post?');
    if (!confirm) return;

    try {
      // Call the context function
      await deletePostById(postId);

      // Filter it out of local state, just like ClipsMyPostsPage
      setResults((current) => current.filter((r) => r.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const navigateToCategoryPosts = (category) => {
    navigate(`/categoryposts/${category}`);
    window.scrollTo(0, 0);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="posts">
      <h2 className="search-results-heading" style={{ textAlign: 'center', marginBottom: '30px' }}>
        {searchQuery ? `Search results for "${searchQuery}"` : 'Search results'}
      </h2>
      {results.length === 0 ? (
        <p style={{ textAlign: 'center' }}>No results found for "{searchQuery}"</p>
      ) : (
        results.map(result => (
          <VisibilityTracker
            key={result.id}
            postId={result.id}
            isDiscoveryBox={false}
            isPostAuthor={result.author === currentUser?.uid}
            onImpressionUpdate={(count) => {
              console.log(`Impression count for post ${result.id}: ${count}`);
            }}
          >
            <div className="post-container">
              <ClipsPost
                {...result}
                navigateToCategoryPosts={navigateToCategoryPosts}
                // 2) Pass handleDelete so the post can be deleted
                onDelete={() => handleDelete(result.id)}
              />
            </div>
          </VisibilityTracker>
        ))
      )}
    </div>
  );
};

export default ClipsSearchResultsPage;
