// VisibilityTracker.js

import React, { useEffect, useRef } from 'react';
import { doc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';

const VisibilityTracker = ({ 
  postId, 
  children, 
  isDiscoveryBox = false 
}) => {
  const containerRef = useRef(null);
  const observerRef = useRef(null);
  const hasIncrementedRef = useRef(false);

  useEffect(() => {
    if (!postId) return;

    // Decide which field in clipsPosts doc to increment
    const fieldToIncrement = isDiscoveryBox
      ? 'discoveryBoxImpressionCount'
      : 'feedImpressionCount';

    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !hasIncrementedRef.current) {
        hasIncrementedRef.current = true;

        // Update doc in clipsPosts
        const postRef = doc(db, 'clipsPosts', postId);
        updateDoc(postRef, { [fieldToIncrement]: increment(1) })
          .catch(err =>
            console.error(
              `VisibilityTracker error incrementing ${fieldToIncrement} for postId=${postId}`,
              err
            )
          );

        // Unobserve so it doesn't increment again
        if (containerRef.current) {
          observerRef.current.unobserve(containerRef.current);
        }
      }
    }, { threshold: 0.5 });

    if (containerRef.current) {
      observerRef.current.observe(containerRef.current);
    }

    return () => {
      if (observerRef.current && containerRef.current) {
        observerRef.current.unobserve(containerRef.current);
      }
      observerRef.current?.disconnect();
    };
  }, [postId, isDiscoveryBox]);

  return <div ref={containerRef}>{children}</div>;
};

export default VisibilityTracker;