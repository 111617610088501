// ClipsPost.js

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Tooltip,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { BsMegaphone } from 'react-icons/bs';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FaRegPaperPlane,
  FaInstagram,
  FaTwitter,
  FaTiktok,
  FaYoutube,
  FaFacebook
} from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import { CiViewList } from 'react-icons/ci';
import { IoMailOutline } from 'react-icons/io5';

import { useAuth } from '../contexts/AuthContext';
import { ClipsReportsContext } from '../contexts/ClipsReportsContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { timeAgo } from '../utils/timeFormatter';
import ClipsReportForm from './ClipsReportForm';
import ChatWindow from './ChatWindow';
import ContactList from './ContactList';
import SharePost from './SharePost';
import { db } from '../utils/firebaseConfig';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore';
import Feedback from './Feedback';
import { FeedbackProvider } from '../contexts/FeedbackContext';
import VisibilityTracker from './VisibilityTracker';
import {
  incrementLinkClickCount,
  incrementInstagramClickCount,
  incrementTwitterClickCount,
  incrementTiktokClickCount,
  incrementYoutubeClickCount,
  incrementFacebookClickCount
} from '../utils/dbUtilities';

const TWO_MINUTES = 2 * 60 * 1000;

/**
 * StyledCard
 * - Removes default border
 * - Keeps a subtle boxShadow for style
 */
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  border: 'none',
  overflow: 'hidden',
  margin: '24px auto',
  width: '100%',
  maxWidth: '550px',
  minWidth: window.innerWidth <= 1180 ? '300px' : '550px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  height: 'auto',
  minHeight: '300px',
  transition: 'max-width 0.3s ease-in-out, padding 0.3s ease-in-out',
  '& > *': {
    width: '100%',
    boxSizing: 'border-box'
  },
  '& .MuiCardMedia-root': {
    border: 'none',
    outline: 'none',
    boxShadow: 'none'
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '300px',
    maxWidth: '100%'
  }
}));

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  width: '100%'
});

// Post Title styling
const StyledTitle = styled(Typography)({
  fontSize: '1.5rem', // ~24px
  fontWeight: 'bold',
  marginBottom: '8px',
  width: '100%',
  textAlign: 'left',
  cursor: 'pointer'
});

// Helper to shorten URLs
const shortenUrl = (url) => {
  const urlWithoutPrefix = url.replace(/^https?:\/\/(www\.)?/i, '');
  const maxLength = 25;
  return urlWithoutPrefix.length > maxLength
    ? `${urlWithoutPrefix.slice(0, maxLength)}...`
    : urlWithoutPrefix;
};

/**
 * Splits text into an array of sentences for bullet points.
 * @param {string} text
 * @returns {string[]}
 */
const splitText = (text) => {
  if (!text) return [];
  return text.split(/(?<=[.?!])\s+(?=[A-Z])/);
};

/**
 * ClipsPost Component
 */
const ClipsPost = ({
  id,
  title,
  author,
  description,
  city,
  state,
  country,
  location,
  categories,
  goals,
  imageUrl,
  timestamp,
  lastEdited,
  link,
  email,
  instagram,
  twitter,
  tiktok,
  youtube,
  facebook,
  isSmall,
  bio,
  profileImageUrl,
  userName,
  onDelete
}) => {
  const navigate = useNavigate();
  const { currentUser, isLoggedIn, isAdmin } = useAuth();
  const { reportPost } = useContext(ClipsReportsContext);
  const { deletePostById } = useContext(ClipsPostsContext);

  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [showReportForm, setShowReportForm] = useState(false);
  const [showContactList, setShowContactList] = useState(false);
  const [showSharePost, setShowSharePost] = useState(false);
  const [isAuthor, setIsAuthor] = useState(
    currentUser && currentUser.displayName === author
  );
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [chatWindowWidth, setChatWindowWidth] = useState(300);

  // We'll store some analytics in local state
  const [post, setPost] = useState({
    isDeleted: false,
    viewCount: 0,
    linkClickCount: 0,
    impressionCount: 0,
    instagramClickCount: 0,
    twitterClickCount: 0,
    tiktokClickCount: 0,
    youtubeClickCount: 0,
    facebookClickCount: 0,
    isReported: false,
    feedImpressionCount: 0,
    discoveryBoxImpressionCount: 0,
    pageViewCount: 0
  });

  // Decide who can see the like count
  const canSeeLikesCount = () => isAuthor || isAdmin;

  // For localStorage visited styling (clickedPosts)
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  // === Real-time Firestore Likes ===
  useEffect(() => {
    const likesQueryRef = query(
      collection(db, 'clipsLikes'),
      where('postId', '==', id)
    );
    const unsubscribe = onSnapshot(likesQueryRef, (snapshot) => {
      const likesArr = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLikeCount(likesArr.length);

      if (currentUser) {
        setIsLiked(likesArr.some((like) => like.userId === currentUser.uid));
        setIsAuthor(currentUser.displayName === author);
      }
    });
    return () => unsubscribe();
  }, [id, currentUser, author]);

  // === 2-Minute Polling for Post Analytics ===
  const fetchPostData = useCallback(async () => {
    if (!id) return;
    try {
      const docRef = doc(db, 'clipsPosts', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setPost((prev) => ({
          ...prev,
          viewCount: data.viewCount || 0,
          linkClickCount: data.linkClickCount || 0,
          impressionCount: data.impressionCount || 0,
          instagramClickCount: data.instagramClickCount || 0,
          twitterClickCount: data.twitterClickCount || 0,
          tiktokClickCount: data.tiktokClickCount || 0,
          youtubeClickCount: data.youtubeClickCount || 0,
          facebookClickCount: data.facebookClickCount || 0,
          isReported: data.isReported || false,
          feedImpressionCount: data.feedImpressionCount || 0,
          discoveryBoxImpressionCount: data.discoveryBoxImpressionCount || 0,
          pageViewCount: data.pageViewCount || 0
        }));
      }
    } catch (err) {
      console.error('Error fetching post data:', err);
    }
  }, [id]);

  useEffect(() => {
    fetchPostData();
    const intervalId = setInterval(() => {
      fetchPostData();
    }, TWO_MINUTES);
    return () => clearInterval(intervalId);
  }, [fetchPostData]);

  // Keep localStorage "clickedPosts" in sync
  useEffect(() => {
    const handleStorage = () => {
      const saved = localStorage.getItem('clickedPosts');
      if (saved) {
        setClickedPosts(JSON.parse(saved));
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  // === Like / Unlike Logic ===
  const handleLike = async (event) => {
    event.stopPropagation();
    if (!isLoggedIn || !currentUser || !id) {
      navigate('/login');
      return;
    }
    if (isLiked) {
      // Unlike
      const likeQueryRef = query(
        collection(db, 'clipsLikes'),
        where('postId', '==', id),
        where('userId', '==', currentUser.uid)
      );
      const snapshot = await getDocs(likeQueryRef);
      const likeDoc = snapshot.docs[0];
      if (likeDoc) {
        await deleteDoc(likeDoc.ref);
      }
    } else {
      // Like
      await addDoc(collection(db, 'clipsLikes'), {
        postId: id,
        userId: currentUser.uid,
        timestamp: new Date()
      });
    }
  };

  // === Navigation & Click Handlers ===
  const handleUsernameClick = (e) => {
    e.stopPropagation();
    navigate(`/clipsusersposts/${author}`);
  };

  const navigateToPostPage = () => {
    navigate(`/clipsposts/${id}`);
  };

  const handleTitleClick = (e) => {
    e.stopPropagation();
    const updatedClickedPosts = { ...clickedPosts, [id]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigateToPostPage();
  };

  // Social link click handlers
  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      link && link.includes('http') ? link : `https://${link}`,
      '_blank',
      'noopener,noreferrer'
    );
    incrementLinkClickCount(id);
  };

  const handleInstagramClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (instagram) {
      incrementInstagramClickCount(id);
      window.open(
        `https://instagram.com/${instagram.replace('@', '')}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const handleTwitterClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (twitter) {
      incrementTwitterClickCount(id);
      window.open(
        `https://twitter.com/${twitter.replace('@', '')}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const handleTiktokClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (tiktok) {
      incrementTiktokClickCount(id);
      window.open(
        `https://tiktok.com/@${tiktok.replace('@', '')}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const handleYoutubeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (youtube) {
      incrementYoutubeClickCount(id);
      window.open(
        `https://youtube.com/@${youtube.replace('@', '')}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const handleFacebookClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (facebook) {
      incrementFacebookClickCount(id);
      window.open(
        `https://www.facebook.com/${facebook}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const navigateToCityPosts = (c) => {
    navigate(`/cityposts/${c}`);
  };
  const navigateToStatePosts = (s) => {
    navigate(`/stateposts/${s}`);
  };
  const navigateToCountryPosts = (ctr) => {
    navigate(`/countryposts/${ctr}`);
  };
  const navigateToLocationPosts = (loc) => {
    navigate(`/locationposts/${loc}`);
  };

  // Reporting
  const toggleReportForm = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowReportForm(!showReportForm);
  };

  // Contact List
  const toggleContactList = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowContactList(!showContactList);
  };
  const closeContactList = () => {
    setShowContactList(false);
  };

  // Sharing
  const toggleSharePost = (e) => {
    e.stopPropagation();
    setShowSharePost((prev) => !prev);
  };
  const closeSharePost = () => {
    setShowSharePost(false);
  };

  // Delete post
  const handleRemove = async (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deletePostById(id);
        if (onDelete) {
          onDelete(id);
        }
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  // Edit post
  const handleEdit = (e) => {
    e.stopPropagation();
    navigate(`/editclipspost/${id}`);
  };

  // Chat
  const openChatWindow = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowChatWindow(true);
  };

  // If post is flagged as deleted in Firestore, hide it
  if (post.isDeleted) return null;

  // If post is reported AND user is not admin => center disclaimer
  if (post.isReported && !isAdmin) {
    return (
      <FeedbackProvider>
        <VisibilityTracker postId={id} isDiscoveryBox={false} isPostAuthor={false}>
          <StyledCard
            sx={{
              margin: '24px auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '200px'
            }}
          >
            <CardContent
              sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Typography variant="h6" sx={{ mb: 1 }}>
                Reported Post Under Review
              </Typography>
              <Typography variant="body1">
                This post has been flagged and is under Admin review.
              </Typography>
            </CardContent>
          </StyledCard>
        </VisibilityTracker>
      </FeedbackProvider>
    );
  }

  return (
    <FeedbackProvider>
      <VisibilityTracker
        postId={id}
        isDiscoveryBox={false}
        isPostAuthor={currentUser?.uid === post.authorId}
      >
        <StyledCard className={isSmall ? 'small-post' : ''}>
          {/* Main Image */}
          {imageUrl && (
            <Box
              sx={{
                overflow: 'hidden',
                width: '100%',
                borderRadius: '8px 8px 0 0',
                lineHeight: 0,
                padding: 0,
                margin: 0
              }}
            >
              <CardMedia
                component="img"
                image={imageUrl}
                alt={title}
                onClick={handleTitleClick}
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  cursor: 'pointer',
                  display: 'block',
                  margin: 0,
                  padding: 0,
                  border: 'none !important',
                  outline: 'none !important',
                  boxShadow: 'none !important'
                }}
              />
            </Box>
          )}

          <StyledCardContent>
            {/* Title + Like Row */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <StyledTitle
                onClick={handleTitleClick}
                sx={{
                  color: clickedPosts[id] ? '#551A8B' : '#1a0dab',
                  marginBottom: 0
                }}
              >
                {title}
              </StyledTitle>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton onClick={handleLike}>
                  {isLiked ? (
                    <Favorite sx={{ color: '#ed4956' }} />
                  ) : (
                    <FavoriteBorder sx={{ color: '#262626' }} />
                  )}
                </IconButton>
                {canSeeLikesCount() && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, textAlign: 'center', minWidth: '15px' }}
                  >
                    {likeCount}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={{ mb: 1 }} />

            {/* Categories */}
            {categories && categories.length > 0 && (
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  width: '100%'
                }}
              >
                {categories.map((category) => (
                  <span
                    key={category}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#f0f0f0',
                      padding: '5px 12px',
                      borderRadius: '16px',
                      fontSize: '14px',
                      color: '#333',
                      border: '1px solid #ddd',
                      fontWeight: 600
                    }}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      navigate(`/categoryposts/${category}`);
                    }}
                    onMouseOver={(ev) => {
                      ev.currentTarget.style.backgroundColor = '#e0e0e0';
                    }}
                    onMouseOut={(ev) => {
                      ev.currentTarget.style.backgroundColor = '#f0f0f0';
                    }}
                  >
                    {category}
                  </span>
                ))}
              </Box>
            )}

            <Box sx={{ mb: 1 }} />

            {/* "What Are You Sharing?" Subheading */}
            <Typography
              variant="h6"
              sx={{
                textAlign: 'left',
                fontWeight: 'bold',
                mt: 2,
                mb: 1,
                color: '#000'
              }}
            >
              What Are You Sharing?
            </Typography>

            {/* Description */}
            <Typography
              variant="body1"
              sx={{ whiteSpace: 'pre-line', mt: 1, textAlign: 'left' }}
            >
              {splitText(description).length > 1 ? (
                <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                  {splitText(description).map((sentence, idx) => (
                    <li key={idx}>{sentence.trim()}</li>
                  ))}
                </ul>
              ) : (
                description
              )}
            </Typography>

            {/* Where Are You? */}
            {(city || state || country || location) && (
              <Box sx={{ mt: 3, mb: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: '#000',
                    mb: 1
                  }}
                >
                  Where Are You?
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '1rem',
                    textAlign: 'left',
                    display: 'inline-block',
                    lineHeight: '1.4'
                  }}
                >
                  {city && (
                    <>
                      <span
                        className="location-link"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigateToCityPosts(city);
                        }}
                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                      >
                        {city}
                      </span>
                    </>
                  )}
                  {city && state ? ', ' : ''}
                  {state && (
                    <>
                      <span
                        className="location-link"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigateToStatePosts(state);
                        }}
                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                      >
                        {state}
                      </span>
                    </>
                  )}
                  {(city || state) && country ? ', ' : ''}
                  {country && (
                    <>
                      <span
                        className="location-link"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigateToCountryPosts(country);
                        }}
                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                      >
                        {country}
                      </span>
                    </>
                  )}
                  {location && (
                    <>
                      {' '}
                      (
                      <span
                        className="location-link"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigateToLocationPosts(location);
                        }}
                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                      >
                        {location}
                      </span>
                      )
                    </>
                  )}
                </Typography>
              </Box>
            )}

            {/* Link */}
            {link && (
              <Box
                sx={{
                  mt: 0,
                  mb: 1,
                  width: '100%',
                  textAlign: 'left'
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    color: '#1a0dab',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontSize: '1rem'
                  }}
                  onClick={handleLinkClick}
                >
                  {shortenUrl(link)}
                </Typography>
              </Box>
            )}

            {/* Posted by + Last Edited */}
            <Typography
              variant="body2"
              color="text.secondary"
              onClick={handleUsernameClick}
              sx={{
                cursor: 'pointer',
                mb: lastEdited ? 0.5 : 2,
                textAlign: 'left',
                mt: link ? 0 : 1
              }}
            >
              Posted {timeAgo(timestamp?.toDate ? timestamp.toDate() : timestamp)} by{' '}
              <span
                style={{
                  color: '#1a0dab',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  textDecoration: 'none'
                }}
              >
                {author}
              </span>
            </Typography>
            {lastEdited && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, textAlign: 'left' }}
              >
                Last Edited {timeAgo(lastEdited?.toDate ? lastEdited.toDate() : lastEdited)}
              </Typography>
            )}

            {/* Total Views: sum of old + new fields */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                mt: 2,
                mb: 2
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: '#000',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '15px',
                  lineHeight: 1.4,
                  fontWeight: 700,
                  textAlign: 'center'
                }}
              >
                <span
                  style={{
                    backgroundColor: '#fff',
                    color: '#000',
                    fontSize: '15px',
                    padding: '3px 8px',
                    border: '2px solid #000',
                    borderRadius: '6px',
                    marginRight: '8px'
                  }}
                >
                  {(
                    (post.impressionCount || 0) +
                    (post.feedImpressionCount || 0) +
                    (post.discoveryBoxImpressionCount || 0) +
                    (post.pageViewCount || 0)
                  ).toLocaleString()}
                </span>
                Total Views
              </Typography>
            </Box>

            {/* Who Are You? */}
            {(userName || bio || profileImageUrl) && (
              <Box sx={{ width: '100%', mt: '15px' }}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: profileImageUrl ? 'center' : 'left',
                    fontWeight: 'bold',
                    color: '#000',
                    mt: 2,
                    mb: 1
                  }}
                >
                  Who Are You?
                </Typography>

                {!profileImageUrl && userName && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'left',
                      mb: 2,
                      mt: 0
                    }}
                  >
                    {userName}
                  </Typography>
                )}

                {profileImageUrl && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        mt: 1
                      }}
                    >
                      <Box
                        sx={{
                          width: '300px',
                          height: '300px',
                          borderRadius: '50%',
                          overflow: 'hidden'
                        }}
                      >
                        <img
                          src={profileImageUrl}
                          alt="Profile"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center'
                          }}
                        />
                      </Box>
                    </Box>

                    {userName && (
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          mt: 1,
                          mb: 2
                        }}
                      >
                        {userName}
                      </Typography>
                    )}
                  </>
                )}

                {bio && (
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: '15px',
                        lineHeight: 1.4,
                        color: '#000',
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        textAlign: 'left'
                      }}
                    >
                      {splitText(bio).length > 1 ? (
                        <ul style={{ paddingLeft: '20px', margin: 0 }}>
                          {splitText(bio).map((sentence, index) => (
                            <li key={index} style={{ marginBottom: '3px' }}>
                              {sentence.trim()}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        bio
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}

            {/* Goals */}
            {goals && Array.isArray(goals) && goals.length > 0 && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    color: '#000',
                    mt: 2,
                    mb: 1
                  }}
                >
                  What's The Goal?
                </Typography>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', mt: 1 }}>
                  {goals.slice(0, 2).map((goal) => (
                    <Box
                      key={goal}
                      sx={{
                        cursor: 'default',
                        backgroundColor: '#e0f4ff',
                        p: '5px 12px',
                        borderRadius: '16px',
                        fontSize: '14px',
                        color: '#333',
                        border: '1px solid #99e0ff',
                        fontWeight: 600,
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {goal}
                    </Box>
                  ))}
                </Box>

                {goals.length > 2 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                      mt: 1,
                      mb: 2
                    }}
                  >
                    {goals.slice(2).map((goal) => (
                      <Box
                        key={goal}
                        sx={{
                          cursor: 'default',
                          backgroundColor: '#e0f4ff',
                          p: '5px 12px',
                          borderRadius: '16px',
                          fontSize: '14px',
                          color: '#333',
                          border: '1px solid #99e0ff',
                          fontWeight: 600,
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {goal}
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            )}

            {/* Give Feedback */}
            <Box sx={{ mt: 2, width: '100%' }}>
              {isLoggedIn ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{ textAlign: 'left', fontWeight: 'bold', mb: 1, color: '#000' }}
                  >
                    Give Feedback
                  </Typography>
                  <Box
                    sx={{
                      mt: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Feedback postId={id} />
                  </Box>
                </>
              ) : (
                /* Single-line (except below 320px fallback) */
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    textAlign: 'left',
                    fontSize: { xs: '0.85rem', sm: '1rem', md: '1.1rem' },
                    py: 1,
                    width: '100%',
                    boxSizing: 'border-box',
                    whiteSpace: 'nowrap',
                    overflowX: 'auto',
                    '@media (max-width:320px)': {
                      whiteSpace: 'normal',
                      overflowX: 'visible'
                    }
                  }}
                >
                  <Box
                    component="span"
                    onClick={() => navigate('/login')}
                    sx={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: 'inherit'
                    }}
                  >
                    Login
                  </Box>{' '}
                  or{' '}
                  <Box
                    component="span"
                    onClick={() => navigate('/signup')}
                    sx={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: 'inherit'
                    }}
                  >
                    Sign Up
                  </Box>{' '}
                  to View &amp; Give Feedback
                </Typography>
              )}
            </Box>

            {/* Follow the Growth */}
            {(instagram || twitter || tiktok || youtube || facebook) && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  mt: 3,
                  mb: 2,
                  width: '100%',
                  gap: 2
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#000',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Follow the Growth!
                </Typography>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {instagram && (
                    <FaInstagram
                      className="social-icon instagram"
                      onClick={handleInstagramClick}
                      style={{ fontSize: '28px', cursor: 'pointer' }}
                    />
                  )}
                  {twitter && (
                    <FaTwitter
                      className="social-icon twitter"
                      onClick={handleTwitterClick}
                      style={{ fontSize: '28px', cursor: 'pointer' }}
                    />
                  )}
                  {tiktok && (
                    <FaTiktok
                      className="social-icon tiktok"
                      onClick={handleTiktokClick}
                      style={{ fontSize: '28px', cursor: 'pointer' }}
                    />
                  )}
                  {youtube && (
                    <FaYoutube
                      className="social-icon youtube"
                      onClick={handleYoutubeClick}
                      style={{ fontSize: '28px', cursor: 'pointer' }}
                    />
                  )}
                  {facebook && (
                    <FaFacebook
                      className="social-icon facebook"
                      onClick={handleFacebookClick}
                      style={{ fontSize: '28px', cursor: 'pointer' }}
                    />
                  )}
                </Box>
              </Box>
            )}

            {/* Contact List, Share, Email, etc. */}
            <Box sx={{ mt: 4, mb: '10px', width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  mb: 2
                }}
              >
                <IconButton
                  onClick={toggleContactList}
                  sx={{ p: '4px', color: '#1a0dab', fontSize: '24px' }}
                >
                  <GoAlert style={{ fontSize: '24px' }} />
                </IconButton>
                <Typography
                  variant="body2"
                  onClick={toggleContactList}
                  sx={{
                    color: '#1a0dab',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize: '1rem'
                  }}
                >
                  Email Updates Sign Up
                </Typography>
              </Box>

              {isAuthor && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    mb: 2
                  }}
                >
                  <IconButton
                    onClick={() => navigate('/view-contact-list')}
                    sx={{ p: '4px', color: '#1a0dab' }}
                  >
                    <CiViewList style={{ fontSize: '24px' }} />
                  </IconButton>
                  <Typography
                    variant="body2"
                    onClick={() => navigate('/view-contact-list')}
                    sx={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      fontSize: '1rem'
                    }}
                  >
                    View Email Sign Ups
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  mb: 2
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSharePost(true);
                }}
              >
                <IconButton
                  sx={{ p: '4px', color: '#1a0dab', fontSize: '24px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSharePost(true);
                  }}
                >
                  <BsMegaphone style={{ fontSize: '24px' }} />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#1a0dab',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    cursor: 'pointer'
                  }}
                >
                  Share this Post
                </Typography>
              </Box>
            </Box>

            {showSharePost && (
              <Box
                sx={{
                  position: 'relative',
                  p: '15px 20px',
                  pt: '50px',
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  backgroundColor: '#f9f9f9',
                  mb: '20px'
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSharePost(false);
                  }}
                  style={{
                    background: 'none',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                    padding: '0',
                    color: '#666',
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    fontWeight: 'bold'
                  }}
                  aria-label="Close Share Options"
                >
                  ×
                </button>
                <SharePost url={`${window.location.origin}/clipsposts/${id}`} />
              </Box>
            )}

            {email && (
              <Box
                sx={{
                  mb: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`mailto:${email}`, '_blank', 'noopener,noreferrer');
                  }}
                  sx={{ p: '4px', color: '#1a0dab', fontSize: '24px' }}
                >
                  <IoMailOutline style={{ fontSize: '24px' }} />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#1a0dab',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize: '1rem'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`mailto:${email}`, '_blank', 'noopener,noreferrer');
                  }}
                >
                  Email
                </Typography>
              </Box>
            )}

            {/* Author's Post Analytics */}
            {isAuthor && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: '20px 16px',
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  mt: '20px',
                  gap: '8px',
                  width: '100%',
                  backgroundColor: '#f8f9fa'
                }}
              >
                <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }}>
                  Your Post Analytics
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  Post Page Views: {post.viewCount || 0}
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  URL Link Clicks: {post.linkClickCount || 0}
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  Post Impressions (Views in Feeds & Discovery Boxes): {post.impressionCount || 0}
                </Typography>
                {instagram && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    Instagram Clicks: {post.instagramClickCount || 0}
                  </Typography>
                )}
                {twitter && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    Twitter Clicks: {post.twitterClickCount || 0}
                  </Typography>
                )}
                {tiktok && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    TikTok Clicks: {post.tiktokClickCount || 0}
                  </Typography>
                )}
                {youtube && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    YouTube Clicks: {post.youtubeClickCount || 0}
                  </Typography>
                )}
                {facebook && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    Facebook Clicks: {post.facebookClickCount || 0}
                  </Typography>
                )}
              </Box>
            )}
          </StyledCardContent>

          {/* Bottom Bar: Edit/Delete or Report/Message */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            {isAuthor ? (
              <>
                <IconButton onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={handleRemove}>
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Tooltip title="Report">
                  <IconButton onClick={toggleReportForm}>
                    <OutlinedFlagIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Send Message">
                  <IconButton onClick={openChatWindow}>
                    <FaRegPaperPlane size={22} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>

          {/* Report Form Modal */}
          {showReportForm && (
            <Box onClick={(e) => e.stopPropagation()}>
              <ClipsReportForm
                postId={id}
                submitReport={reportPost}
                closeModal={() => setShowReportForm(false)}
              />
            </Box>
          )}

          {/* Chat Window */}
          {showChatWindow && (
            <ChatWindow
              initialRecipient={author}
              onClose={() => setShowChatWindow(false)}
              width={chatWindowWidth}
              setWidth={setChatWindowWidth}
            />
          )}

          {/* Contact List Modal */}
          {showContactList && <ContactList postId={id} closeModal={closeContactList} />}
        </StyledCard>
      </VisibilityTracker>
    </FeedbackProvider>
  );
};

export default ClipsPost;
