// ClipsPostPage.js

import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  Tooltip,
  Fade,
  Paper,
  List,
  ListItem,
  Typography,
  CardMedia,
  useMediaQuery
} from '@mui/material';

import { BsMegaphone } from 'react-icons/bs';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  FaRegPaperPlane,
  FaInstagram,
  FaTwitter,
  FaTiktok,
  FaYoutube,
  FaFacebook
} from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import { CiViewList } from 'react-icons/ci';
import { IoMailOutline } from 'react-icons/io5';

import { useAuth } from '../contexts/AuthContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { ClipsReportsContext } from '../contexts/ClipsReportsContext';
import { timeAgo } from '../utils/timeFormatter';
import { db } from '../utils/firebaseConfig';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore';
import {
  incrementPageViewCount,
  incrementLinkClickCount,
  incrementInstagramClickCount,
  incrementTwitterClickCount,
  incrementTiktokClickCount,
  incrementYoutubeClickCount,
  incrementFacebookClickCount,
  getClipsPostById
} from '../utils/dbUtilities';

import ClipsReportForm from './ClipsReportForm';
import ChatWindow from './ChatWindow';
import ContactList from './ContactList';
import Feedback from './Feedback';
import { FeedbackProvider } from '../contexts/FeedbackContext';
import VisibilityTracker from './VisibilityTracker';
import SharePost from './SharePost';

import { styled } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';

import './Post.css';

// For 2-minute polling of Firestore analytics
const TWO_MINUTES = 2 * 60 * 1000;

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  overflow: 'hidden',
  margin: '24px auto',
  width: '100%',
  maxWidth: '550px',
  minWidth: window.innerWidth <= 1180 ? '300px' : '550px',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  height: 'auto',
  minHeight: '300px',
  transition: 'max-width 0.3s ease-in-out, padding 0.3s ease-in-out',
  '& > *': {
    width: '100%',
    boxSizing: 'border-box'
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '300px',
    maxWidth: '100%'
  }
}));

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  width: '100%'
});

const StyledTitle = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: '8px',
  width: '100%',
  textAlign: 'left',
  cursor: 'pointer'
});

// Helper to split text into bullet points
const splitText = (text) => {
  if (!text) return [];
  return text.split(/(?<=[.?!])\s+(?=[A-Z])/);
};

// Helper to shorten URLs
const shortenUrl = (url) => {
  const urlWithoutPrefix = url.replace(/^https?:\/\/(www\.)?/i, '');
  const maxLength = 25;
  return urlWithoutPrefix.length > maxLength
    ? `${urlWithoutPrefix.slice(0, maxLength)}...`
    : urlWithoutPrefix;
};

const ClipsPostPage = () => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 1024px)');
  const isSurfacePro7 = useMediaQuery('(min-width: 1368px) and (max-width: 1368px)');

  const { id } = useParams();
  const navigate = useNavigate();
  const { posts, deletePostById } = useContext(ClipsPostsContext);
  const { reportPost } = useContext(ClipsReportsContext);
  const { currentUser, isLoggedIn, isAdmin } = useAuth();

  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [isAuthor, setIsAuthor] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);
  const [showContactList, setShowContactList] = useState(false);
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [showSharePost, setShowSharePost] = useState(false);

  // For visited-link styling, not local impressions
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  // For "Discover Posts"
  const [randomPostsKey, setRandomPostsKey] = useState(0);

  // On mount or when ID changes, fetch or find post
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!id) {
      setIsLoading(false);
      return;
    }

    // Check if this post is in context already
    const foundInContext = posts.find((p) => p.id?.toString() === id);
    if (foundInContext) {
      setPost(foundInContext);
      setIsLoading(false);
    } else {
      // else fetch from Firestore
      (async () => {
        try {
          const docFromDb = await getClipsPostById(id);
          setPost(docFromDb || null);
        } catch (err) {
          console.error('Error fetching older post by ID:', err);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [posts, id]);

  // Once we know which post, check if it's deleted or if currentUser is author
  useEffect(() => {
    if (!isLoading && post) {
      if (post.deleted) {
        setIsDeleted(true);
      } else if (currentUser && currentUser.displayName === post.author) {
        setIsAuthor(true);
      }
    } else if (!isLoading && !post) {
      setIsDeleted(true);
    }
  }, [isLoading, post, currentUser]);

  // Increment page view on mount
  useEffect(() => {
    if (post?.id) {
      incrementPageViewCount(post.id);
    }
  }, [post?.id]);

  // Real-time listen for likes
  useEffect(() => {
    if (!post?.id) return;
    const likesQueryRef = query(collection(db, 'clipsLikes'), where('postId', '==', post.id));
    const unsubscribe = onSnapshot(likesQueryRef, (snapshot) => {
      const likesData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLikeCount(likesData.length);

      if (currentUser) {
        const userLike = likesData.some((like) => like.userId === currentUser.uid);
        setPost((prev) => (prev ? { ...prev, liked: userLike } : null));
      }
    });
    return () => unsubscribe();
  }, [post, currentUser]);

  // Poll Firestore every 2 minutes to keep analytics up-to-date
  const fetchPostData = useCallback(async () => {
    if (!post?.id) return;
    try {
      const docRef = doc(db, 'clipsPosts', post.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setPost((prev) =>
          prev
            ? {
                ...prev,
                viewCount: data.viewCount || 0,
                linkClickCount: data.linkClickCount || 0,
                impressionCount: data.impressionCount || 0,
                instagramClickCount: data.instagramClickCount || 0,
                twitterClickCount: data.twitterClickCount || 0,
                tiktokClickCount: data.tiktokClickCount || 0,
                youtubeClickCount: data.youtubeClickCount || 0,
                facebookClickCount: data.facebookClickCount || 0,
                isReported: data.isReported || false,
                feedImpressionCount: data.feedImpressionCount || 0,
                discoveryBoxImpressionCount: data.discoveryBoxImpressionCount || 0,
                pageViewCount: data.pageViewCount || 0
              }
            : prev
        );
      }
    } catch (err) {
      console.error('Error polling post data:', err);
    }
  }, [post?.id]);

  useEffect(() => {
    if (!post?.id) return;
    fetchPostData();
    const intervalId = setInterval(() => {
      fetchPostData();
    }, TWO_MINUTES);
    return () => clearInterval(intervalId);
  }, [post?.id, fetchPostData]);

  // Keep localStorage "clickedPosts" in sync
  useEffect(() => {
    const handleStorage = () => {
      const saved = localStorage.getItem('clickedPosts');
      if (saved) {
        setClickedPosts(JSON.parse(saved));
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  // Generate random post suggestions (excluding the current one)
  const randomPosts = useMemo(() => {
    const validPosts = posts.filter((p) => p.id !== id && !p.deleted && !p.isReported);
    const shuffled = [...validPosts].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, 3);
  }, [posts, id, randomPostsKey]);

  const handleRefresh = () => {
    setRandomPostsKey((prev) => prev + 1);
  };

  // Only author or admin sees likeCount
  const canSeeLikesCount = useCallback(() => {
    return isAuthor || isAdmin;
  }, [isAuthor, isAdmin]);

  if (isLoading) {
    return null; // or a spinner, etc.
  }

  if (isDeleted || !post) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
          pt: { xs: 10, sm: 12, md: 15, lg: 15, xl: 15 },
          ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' },
          '@media (min-width: 1030px) and (max-width: 1300px)': { mt: -5 }
        }}
        className="post-page-container"
      >
        <Typography variant="h6">This Post No Longer Exists</Typography>
      </Box>
    );
  }

  // If the post is reported & user not admin => disclaim
  if (post.isReported && !isAdmin) {
    return (
      <FeedbackProvider>
        <VisibilityTracker postId={id} isDiscoveryBox={false} isPostAuthor={false}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 2,
              pt: { xs: 10, sm: 12, md: 15, lg: 15, xl: 15 },
              ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' }
            }}
            className="post-page-container"
          >
            <StyledCard
              sx={{
                margin: '24px auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px'
              }}
            >
              <CardContent
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Reported Post Under Review
                </Typography>
                <Typography variant="body1">
                  This post has been flagged and is under Admin review.
                </Typography>
              </CardContent>
            </StyledCard>
          </Box>
        </VisibilityTracker>
      </FeedbackProvider>
    );
  }

  const handlePostClick = () => {
    const updated = { ...clickedPosts, [post.id]: true };
    setClickedPosts(updated);
    localStorage.setItem('clickedPosts', JSON.stringify(updated));
  };

  // Like/unlike logic
  const handleLike = async () => {
    if (!isLoggedIn || !currentUser) {
      navigate('/login');
      return;
    }
    if (post.liked) {
      const likeQueryRef = query(
        collection(db, 'clipsLikes'),
        where('postId', '==', post.id),
        where('userId', '==', currentUser.uid)
      );
      const snapshot = await getDocs(likeQueryRef);
      const likeDoc = snapshot.docs[0];
      if (likeDoc) {
        await deleteDoc(likeDoc.ref);
      }
    } else {
      await addDoc(collection(db, 'clipsLikes'), {
        postId: post.id,
        userId: currentUser.uid,
        timestamp: new Date()
      });
    }
  };

  // Delete post
  const handleRemove = async () => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deletePostById(id);
        setIsDeleted(true);
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  // Toggle forms
  const toggleReportForm = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowReportForm((prev) => !prev);
  };

  const toggleContactList = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowContactList((prev) => !prev);
  };
  const closeContactList = () => setShowContactList(false);

  // Chat
  const openChatWindow = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowChatWindow(true);
  };

  // Category, userName, location clicks
  const navigateToCategoryPosts = (category) => {
    navigate(`/categoryposts/${category}`);
  };
  const handleUsernameClick = (e) => {
    e.stopPropagation();
    navigate(`/clipsusersposts/${post.author}`);
  };
  const navigateToCityPosts = (city) => navigate(`/cityposts/${city}`);
  const navigateToStatePosts = (state) => navigate(`/stateposts/${state}`);
  const navigateToCountryPosts = (country) => navigate(`/countryposts/${country}`);
  const navigateToLocationPosts = (loc) => navigate(`/locationposts/${loc}`);

  // Link & social clicks
  const handleLinkClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (post?.id) await incrementLinkClickCount(post.id);
    const href = post.link.includes('http') ? post.link : `https://${post.link}`;
    window.open(href, '_blank', 'noopener,noreferrer');
  };

  const handleInstagramClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (post?.id) await incrementInstagramClickCount(post.id);
    window.open(`https://instagram.com/${post.instagram.replace('@', '')}`, '_blank');
  };

  const handleTwitterClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (post?.id) await incrementTwitterClickCount(post.id);
    window.open(`https://twitter.com/${post.twitter.replace('@', '')}`, '_blank');
  };

  const handleTiktokClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (post?.id) await incrementTiktokClickCount(post.id);
    window.open(`https://tiktok.com/@${post.tiktok.replace('@', '')}`, '_blank');
  };

  const handleYoutubeClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (post?.id) await incrementYoutubeClickCount(post.id);
    window.open(`https://youtube.com/@${post.youtube.replace('@', '')}`, '_blank');
  };

  const handleFacebookClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (post?.id) await incrementFacebookClickCount(post.id);
    window.open(`https://www.facebook.com/${post.facebook}`, '_blank');
  };

  // Timestamps
  const postDate = post.timestamp?.toDate ? post.timestamp.toDate() : post.timestamp;
  const lastEditedDate = post.lastEdited?.toDate ? post.lastEdited.toDate() : post.lastEdited;

  return (
    <FeedbackProvider>
      <VisibilityTracker
        postId={id}
        isDiscoveryBox={false}
        isPostAuthor={currentUser?.displayName === post.author}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            pt: { xs: 10, sm: 12, md: 15, lg: 15, xl: 15 },
            ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' },
            '@media (min-width: 1030px) and (max-width: 1300px)': {
              mt: -5
            }
          }}
          className="post-page-container"
        >
          <StyledCard
            onClick={handlePostClick}
            sx={{
              maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
              mb: 2,
              marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
              marginRight: isSurfacePro7 ? 'auto' : '0'
            }}
          >
            {post.imageUrl && (
              <CardMedia
                component="img"
                image={post.imageUrl}
                alt={post.title}
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  cursor: 'pointer'
                }}
              />
            )}

            <StyledCardContent>
              {/* Title + Like Row */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <StyledTitle
                  onClick={(e) => {
                    e.stopPropagation();
                    const updated = { ...clickedPosts, [post.id]: true };
                    setClickedPosts(updated);
                    localStorage.setItem('clickedPosts', JSON.stringify(updated));
                  }}
                  sx={{
                    color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                    marginBottom: 0
                  }}
                >
                  {post.title}
                </StyledTitle>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <IconButton onClick={handleLike}>
                    {post.liked ? (
                      <Favorite sx={{ color: '#ed4956' }} />
                    ) : (
                      <FavoriteBorder sx={{ color: '#262626' }} />
                    )}
                  </IconButton>
                  {canSeeLikesCount() && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 600, textAlign: 'center', minWidth: '15px' }}
                    >
                      {likeCount}
                    </Typography>
                  )}
                </Box>
              </Box>

              {/* Categories */}
              {post.categories && post.categories.length > 0 && (
                <Box
                  sx={{
                    mt: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    width: '100%'
                  }}
                >
                  {post.categories.map((category) => (
                    <span
                      key={category}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: '#f0f0f0',
                        padding: '5px 12px',
                        borderRadius: '16px',
                        fontSize: '14px',
                        color: '#333',
                        border: '1px solid #ddd',
                        fontWeight: 600
                      }}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        navigateToCategoryPosts(category);
                      }}
                      onMouseOver={(ev) => {
                        ev.currentTarget.style.backgroundColor = '#e0e0e0';
                      }}
                      onMouseOut={(ev) => {
                        ev.currentTarget.style.backgroundColor = '#f0f0f0';
                      }}
                    >
                      {category}
                    </span>
                  ))}
                </Box>
              )}

              <Box sx={{ mb: 1 }} />

              {/* "What Are You Sharing?" */}
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                  mt: 2,
                  mb: 1,
                  color: '#000'
                }}
              >
                What Are You Sharing?
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: 'pre-line',
                  mt: 1,
                  textAlign: 'left'
                }}
              >
                {splitText(post.description).length > 1 ? (
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                    {splitText(post.description).map((sentence, idx) => (
                      <li key={idx}>{sentence.trim()}</li>
                    ))}
                  </ul>
                ) : (
                  post.description
                )}
              </Typography>

              {/* Where Are You? */}
              {(post.city || post.state || post.country || post.location) && (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 3,
                      mb: 1,
                      textAlign: 'left',
                      fontWeight: 'bold',
                      color: '#000'
                    }}
                  >
                    Where Are You?
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 1,
                      mb: 2,
                      fontSize: '1rem',
                      textAlign: 'left',
                      display: 'inline-block',
                      lineHeight: '1.4'
                    }}
                  >
                    {post.city && (
                      <>
                        <span
                          className="location-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToCityPosts(post.city);
                          }}
                          style={{ cursor: 'pointer', textDecoration: 'none' }}
                        >
                          {post.city}
                        </span>
                      </>
                    )}
                    {post.city && post.state ? ', ' : ''}
                    {post.state && (
                      <>
                        <span
                          className="location-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToStatePosts(post.state);
                          }}
                          style={{ cursor: 'pointer', textDecoration: 'none' }}
                        >
                          {post.state}
                        </span>
                      </>
                    )}
                    {(post.city || post.state) && post.country ? ', ' : ''}
                    {post.country && (
                      <>
                        <span
                          className="location-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToCountryPosts(post.country);
                          }}
                          style={{ cursor: 'pointer', textDecoration: 'none' }}
                        >
                          {post.country}
                        </span>
                      </>
                    )}
                    {post.location && (
                      <>
                        {' '}
                        (
                        <span
                          className="location-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToLocationPosts(post.location);
                          }}
                          style={{ cursor: 'pointer', textDecoration: 'none' }}
                        >
                          {post.location}
                        </span>
                        )
                      </>
                    )}
                  </Typography>
                </>
              )}

              {/* Link */}
              {post.link && (
                <Box sx={{ mt: 0, mb: 1, width: '100%', textAlign: 'left' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      color: '#1a0dab',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      textDecoration: 'none',
                      fontSize: '1rem'
                    }}
                    onClick={handleLinkClick}
                  >
                    {shortenUrl(post.link)}
                  </Typography>
                </Box>
              )}

              {/* Posted by */}
              <Typography
                variant="body2"
                color="text.secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/clipsusersposts/${post.author}`);
                }}
                sx={{
                  cursor: 'pointer',
                  mb: post.lastEdited ? 0.5 : 2,
                  textAlign: 'left',
                  mt: post.link ? 0 : 1
                }}
              >
                Posted {timeAgo(postDate)} by{' '}
                <span
                  style={{
                    color: '#1a0dab',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'none'
                  }}
                >
                  {post.author}
                </span>
              </Typography>
              {lastEditedDate && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2, textAlign: 'left' }}
                >
                  Last Edited {timeAgo(lastEditedDate)}
                </Typography>
              )}

              {/* Total Views: sum old + new */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  mt: 2,
                  mb: 2
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#000',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '15px',
                    lineHeight: 1.4,
                    fontWeight: 700,
                    textAlign: 'center'
                  }}
                >
                  <span
                    style={{
                      backgroundColor: '#fff',
                      color: '#000',
                      fontSize: '15px',
                      padding: '3px 8px',
                      border: '2px solid #000',
                      borderRadius: '6px',
                      marginRight: '8px'
                    }}
                  >
                    {(
                      (post.impressionCount || 0) +
                      (post.feedImpressionCount || 0) +
                      (post.discoveryBoxImpressionCount || 0) +
                      (post.pageViewCount || 0)
                    ).toLocaleString()}
                  </span>
                  Total Views
                </Typography>
              </Box>

              {/* Who Are You? */}
              {(post.userName || post.bio || post.profileImageUrl) && (
                <Box sx={{ width: '100%', mt: '15px' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: post.profileImageUrl ? 'center' : 'left',
                      fontWeight: 'bold',
                      color: '#000',
                      mt: 2,
                      mb: 1
                    }}
                  >
                    Who Are You?
                  </Typography>

                  {!post.profileImageUrl && post.userName && (
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        mb: 2,
                        mt: 0
                      }}
                    >
                      {post.userName}
                    </Typography>
                  )}

                  {post.profileImageUrl && (
                    <>
                      <Box
                        sx={{
                          width: '300px',
                          height: '300px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0 auto',
                          mt: 1
                        }}
                      >
                        <img
                          src={post.profileImageUrl}
                          alt="Profile"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center'
                          }}
                        />
                      </Box>

                      {post.userName && (
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            mt: 1,
                            mb: 2
                          }}
                        >
                          {post.userName}
                        </Typography>
                      )}
                    </>
                  )}

                  {post.bio && (
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontSize: '15px',
                          lineHeight: 1.4,
                          color: '#000',
                          fontFamily: '"Roboto", sans-serif',
                          fontWeight: 400,
                          textAlign: 'left'
                        }}
                      >
                        {splitText(post.bio).length > 1 ? (
                          <ul style={{ paddingLeft: '20px', margin: 0 }}>
                            {splitText(post.bio).map((sentence, index) => (
                              <li key={index} style={{ marginBottom: '3px' }}>
                                {sentence.trim()}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          post.bio
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}

              {/* Goals */}
              {post.goals && Array.isArray(post.goals) && post.goals.length > 0 && (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: 'left',
                      fontWeight: 'bold',
                      color: '#000',
                      mt: 2,
                      mb: 1
                    }}
                  >
                    What's The Goal?
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', mt: 1 }}>
                    {post.goals.slice(0, 2).map((goal) => (
                      <Box
                        key={goal}
                        sx={{
                          cursor: 'default',
                          backgroundColor: '#e0f4ff',
                          p: '5px 12px',
                          borderRadius: '16px',
                          fontSize: '14px',
                          color: '#333',
                          border: '1px solid #99e0ff',
                          fontWeight: 600,
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {goal}
                      </Box>
                    ))}
                  </Box>

                  {post.goals.length > 2 && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', mt: 1, mb: 2 }}>
                      {post.goals.slice(2).map((goal) => (
                        <Box
                          key={goal}
                          sx={{
                            cursor: 'default',
                            backgroundColor: '#e0f4ff',
                            p: '5px 12px',
                            borderRadius: '16px',
                            fontSize: '14px',
                            color: '#333',
                            border: '1px solid #99e0ff',
                            fontWeight: 600,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {goal}
                        </Box>
                      ))}
                    </Box>
                  )}
                </>
              )}

              {/* Give Feedback */}
              <Box sx={{ mt: 2, width: '100%' }}>
                {isLoggedIn ? (
                  <>
                    <Typography
                      variant="h6"
                      sx={{ textAlign: 'left', fontWeight: 'bold', mb: 1, color: '#000' }}
                    >
                      Give Feedback
                    </Typography>
                    <Box
                      sx={{
                        mt: '10px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Feedback postId={id} />
                    </Box>
                  </>
                ) : (
                  /* Single-line except below 320px fallback */
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                      textAlign: 'left',
                      fontSize: { xs: '0.85rem', sm: '1rem', md: '1.1rem' },
                      py: 1,
                      width: '100%',
                      boxSizing: 'border-box',
                      whiteSpace: 'nowrap',
                      overflowX: 'auto',
                      '@media (max-width:320px)': {
                        whiteSpace: 'normal',
                        overflowX: 'visible'
                      }
                    }}
                  >
                    <Box
                      component="span"
                      onClick={() => navigate('/login')}
                      sx={{
                        color: '#1a0dab',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        fontSize: 'inherit'
                      }}
                    >
                      Login
                    </Box>{' '}
                    or{' '}
                    <Box
                      component="span"
                      onClick={() => navigate('/signup')}
                      sx={{
                        color: '#1a0dab',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        fontSize: 'inherit'
                      }}
                    >
                      Sign Up
                    </Box>{' '}
                    to View &amp; Give Feedback
                  </Typography>
                )}
              </Box>

              {/* Social links */}
              {(post.instagram ||
                post.twitter ||
                post.tiktok ||
                post.youtube ||
                post.facebook) && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    mt: 3,
                    mb: 2,
                    width: '100%',
                    gap: 2
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: '#000',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Follow the Growth!
                  </Typography>

                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {post.instagram && (
                      <FaInstagram
                        className="social-icon instagram"
                        onClick={async (e) => {
                          e.stopPropagation();
                          await incrementInstagramClickCount(id);
                          window.open(
                            `https://instagram.com/${post.instagram.replace('@', '')}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                        style={{ fontSize: '28px', cursor: 'pointer' }}
                      />
                    )}
                    {post.twitter && (
                      <FaTwitter
                        className="social-icon twitter"
                        onClick={async (e) => {
                          e.stopPropagation();
                          await incrementTwitterClickCount(id);
                          window.open(
                            `https://twitter.com/${post.twitter.replace('@', '')}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                        style={{ fontSize: '28px', cursor: 'pointer' }}
                      />
                    )}
                    {post.tiktok && (
                      <FaTiktok
                        className="social-icon tiktok"
                        onClick={async (e) => {
                          e.stopPropagation();
                          await incrementTiktokClickCount(id);
                          window.open(
                            `https://tiktok.com/@${post.tiktok.replace('@', '')}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                        style={{ fontSize: '28px', cursor: 'pointer' }}
                      />
                    )}
                    {post.youtube && (
                      <FaYoutube
                        className="social-icon youtube"
                        onClick={async (e) => {
                          e.stopPropagation();
                          await incrementYoutubeClickCount(id);
                          window.open(
                            `https://youtube.com/@${post.youtube.replace('@', '')}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                        style={{ fontSize: '28px', cursor: 'pointer' }}
                      />
                    )}
                    {post.facebook && (
                      <FaFacebook
                        className="social-icon facebook"
                        onClick={async (e) => {
                          e.stopPropagation();
                          await incrementFacebookClickCount(id);
                          window.open(
                            `https://www.facebook.com/${post.facebook}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                        style={{ fontSize: '28px', cursor: 'pointer' }}
                      />
                    )}
                  </Box>
                </Box>
              )}

              {/* ContactList, Share, etc. */}
              <Box sx={{ mt: 4, mb: '10px', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: 2 }}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleContactList(e);
                    }}
                    sx={{ p: '4px', color: '#1a0dab', fontSize: '24px' }}
                  >
                    <GoAlert style={{ fontSize: '24px' }} />
                  </IconButton>
                  <Typography
                    variant="body2"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleContactList(e);
                    }}
                    sx={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      fontSize: '1rem'
                    }}
                  >
                    Email Updates Sign Up
                  </Typography>
                </Box>

                {isAuthor && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: 2 }}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/view-contact-list');
                      }}
                      sx={{ p: '4px', color: '#1a0dab' }}
                    >
                      <CiViewList style={{ fontSize: '24px' }} />
                    </IconButton>
                    <Typography
                      variant="body2"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/view-contact-list');
                      }}
                      sx={{
                        color: '#1a0dab',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '1rem'
                      }}
                    >
                      View Email Sign Ups
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: 2 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSharePost(true);
                  }}
                >
                  <IconButton sx={{ p: '4px', color: '#1a0dab', fontSize: '24px' }}>
                    <BsMegaphone style={{ fontSize: '24px' }} />
                  </IconButton>
                  <Typography
                    variant="body2"
                    sx={{ color: '#1a0dab', fontWeight: 'bold', fontSize: '1rem', cursor: 'pointer' }}
                  >
                    Share this Post
                  </Typography>
                </Box>
              </Box>

              {showSharePost && (
                <Box
                  sx={{
                    position: 'relative',
                    p: '15px 20px',
                    pt: '50px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9',
                    mb: '20px'
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSharePost(false);
                    }}
                    style={{
                      background: 'none',
                      border: 'none',
                      fontSize: '24px',
                      cursor: 'pointer',
                      padding: '0',
                      color: '#666',
                      position: 'absolute',
                      top: '10px',
                      left: '10px',
                      fontWeight: 'bold'
                    }}
                    aria-label="Close Share Options"
                  >
                    ×
                  </button>
                  <SharePost url={`${window.location.origin}/clipsposts/${id}`} />
                </Box>
              )}

              {post.email && (
                <Box sx={{ mb: '20px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(`mailto:${post.email}`, '_blank', 'noopener,noreferrer');
                    }}
                    sx={{ p: '4px', color: '#1a0dab', fontSize: '24px' }}
                  >
                    <IoMailOutline style={{ fontSize: '24px' }} />
                  </IconButton>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      fontSize: '1rem'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(`mailto:${post.email}`, '_blank', 'noopener,noreferrer');
                    }}
                  >
                    Email
                  </Typography>
                </Box>
              )}

              {isAuthor && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    p: '20px 16px',
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    mt: '20px',
                    gap: '8px',
                    width: '100%',
                    backgroundColor: '#f8f9fa'
                  }}
                >
                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }}>
                    Your Post Analytics
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    Post Page Views: {post.viewCount || 0}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    URL Link Clicks: {post.linkClickCount || 0}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    Post Impressions (Views in Feeds & Discovery Boxes): {post.impressionCount || 0}
                  </Typography>
                  {post.instagram && (
                    <Typography variant="body2" sx={{ color: '#000' }}>
                      Instagram Clicks: {post.instagramClickCount || 0}
                    </Typography>
                  )}
                  {post.twitter && (
                    <Typography variant="body2" sx={{ color: '#000' }}>
                      Twitter Clicks: {post.twitterClickCount || 0}
                    </Typography>
                  )}
                  {post.tiktok && (
                    <Typography variant="body2" sx={{ color: '#000' }}>
                      TikTok Clicks: {post.tiktokClickCount || 0}
                    </Typography>
                  )}
                  {post.youtube && (
                    <Typography variant="body2" sx={{ color: '#000' }}>
                      YouTube Clicks: {post.youtubeClickCount || 0}
                    </Typography>
                  )}
                  {post.facebook && (
                    <Typography variant="body2" sx={{ color: '#000' }}>
                      Facebook Clicks: {post.facebookClickCount || 0}
                    </Typography>
                  )}
                </Box>
              )}
            </StyledCardContent>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              {isAuthor ? (
                <>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/editclipspost/${id}`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemove();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Report">
                    <IconButton onClick={toggleReportForm}>
                      <OutlinedFlagIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Send Message">
                    <IconButton onClick={openChatWindow}>
                      <FaRegPaperPlane size={22} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>

            {showReportForm && (
              <Box onClick={(e) => e.stopPropagation()}>
                <ClipsReportForm
                  postId={id}
                  submitReport={reportPost}
                  closeModal={() => setShowReportForm(false)}
                />
              </Box>
            )}

            {showChatWindow && (
              <ChatWindow
                initialRecipient={post.author}
                onClose={() => setShowChatWindow(false)}
                width={300}
                setWidth={() => {}}
              />
            )}

            {showContactList && <ContactList postId={id} closeModal={closeContactList} />}
          </StyledCard>

          {randomPosts.length > 0 && (
            <Fade in={true} timeout={800}>
              <Paper
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 2,
                  pt: 2,
                  pb: 2,
                  mt: 2,
                  width: '100%',
                  maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
                  mb: 2,
                  marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
                  marginRight: isSurfacePro7 ? 'auto' : '0',
                  borderRadius: '16px',
                  backgroundColor: '#fff',
                  overflow: 'hidden'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    mb: 1,
                    flexWrap: 'nowrap',
                    gap: { xs: 1, sm: 2 }
                  }}
                >
                  <Typography
                    variant="h4"
                    align="left"
                    sx={{
                      fontWeight: '700',
                      color: '#1d1d1f',
                      lineHeight: 1.2,
                      fontSize: {
                        xs: '1.3rem',
                        sm: '1.5rem',
                        md: '1.8rem'
                      },
                      textAlign: 'left',
                      overflow: 'visible',
                      whiteSpace: 'normal',
                      maxWidth: { xs: '70%', sm: '80%' }
                    }}
                  >
                    Discover Posts
                  </Typography>
                  <IconButton
                    onClick={handleRefresh}
                    sx={{
                      color: '#007aff',
                      padding: { xs: '6px', sm: '8px' },
                      backgroundColor: '#f5f5f7',
                      '&:hover': { backgroundColor: '#e8e8ed' },
                      transition: 'background-color 0.3s ease',
                      flexShrink: 0,
                      ml: 'auto'
                    }}
                  >
                    <RefreshIcon fontSize={isSmallScreen ? 'small' : 'medium'} />
                  </IconButton>
                </Box>
                <List sx={{ width: '100%', padding: 0, mt: 0 }}>
                  {randomPosts.map((randomPost, index) => {
                    const clickedStyle = clickedPosts[randomPost.id] ? { color: '#551A8B' } : {};
                    return (
                      <VisibilityTracker
                        key={randomPost.id}
                        postId={randomPost.id}
                        isDiscoveryBox={true}
                      >
                        <Fade in={true} timeout={500 + index * 100}>
                          <ListItem
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              padding: '8px 0',
                              borderRadius: '8px',
                              '&:hover': { backgroundColor: 'transparent' }
                            }}
                            onClick={() => {
                              const updated = { ...clickedPosts, [randomPost.id]: true };
                              setClickedPosts(updated);
                              localStorage.setItem('clickedPosts', JSON.stringify(updated));
                              navigate(`/clipsposts/${randomPost.id}`);
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{
                                  textDecoration: 'none',
                                  cursor: 'pointer',
                                  fontWeight: 'bold',
                                  fontSize: '1.1rem',
                                  marginBottom: '4px',
                                  color: clickedPosts[randomPost.id] ? '#551A8B' : '#1a0dab',
                                  ...clickedStyle
                                }}
                              >
                                {randomPost.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                sx={{ color: '#86868b', fontSize: '0.9rem' }}
                              >
                                {randomPost.city && randomPost.state
                                  ? `(${randomPost.city}, ${randomPost.state})`
                                  : randomPost.city
                                  ? `(${randomPost.city})`
                                  : randomPost.state
                                  ? `(${randomPost.state})`
                                  : ''}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#1d1d1f',
                                fontWeight: 400,
                                fontSize: '0.95rem'
                              }}
                            >
                              {randomPost.summary ||
                                (randomPost.description?.length > 100
                                  ? `${randomPost.description.substring(0, 100)}...`
                                  : randomPost.description)}
                            </Typography>
                          </ListItem>
                        </Fade>
                      </VisibilityTracker>
                    );
                  })}
                </List>
              </Paper>
            </Fade>
          )}
        </Box>
      </VisibilityTracker>
    </FeedbackProvider>
  );
};

export default ClipsPostPage;
