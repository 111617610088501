// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { analytics } from './utils/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import HomePage from './components/HomePage';  // Re-added HomePage
import ClipsSearchResultsPage from './components/ClipsSearchResultsPage';
import ChatWindow from './components/ChatWindow';
import NewPosts from './components/NewPosts';
import LoginPage from './components/LoginPage';
import MessagesPage from './components/MessagesPage';
import SignUpPage from './components/SignUpPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import InfoPage from './components/InfoPage';
import ClipsContactForm from './components/ClipsContactForm';
import ChatComponent from './components/ChatComponent';
import ExplorePage from './components/ExplorePage';
import ClipsHomePage from './components/ClipsHomePage';
import ClipsPostForm from './components/ClipsPostForm';
import ClipsPostPage from './components/ClipsPostPage';
import ClipsLikesPage from './components/ClipsLikesPage';
import ClipsMyPostsPage from './components/ClipsMyPostsPage';
import ClipsEditPostForm from './components/ClipsEditPostForm';
import ClipsAdminDashboard from './components/ClipsAdminDashboard';
import ClipsViewReports from './components/ClipsViewReports';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';
import ActiveItemContext from './contexts/ActiveItemContext';
import { MessagesProvider } from './contexts/MessagesContext';
import UsernamesContext from './contexts/UsernamesContext';
import { BlockProvider } from './contexts/BlockContext';
import { UsersProvider } from './contexts/UsersContext';
import { ClipsPostsProvider } from './contexts/ClipsPostsContext';
import { ClipsLikesProvider } from './contexts/ClipsLikesContext';
import { ClipsReportsProvider } from './contexts/ClipsReportsContext';
import VisibilityTracker from './components/VisibilityTracker';
import Trending from './components/Trending';
import './App.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { db } from './utils/firebaseConfig';
import TitleManager from './components/TitleManager';

// Import the new ClipsUsersPosts component
import ClipsUsersPosts from './components/ClipsUsersPosts';

// Import the new CategoryPosts component
import CategoryPosts from './components/CategoryPosts';

// Import the new CityPosts, StatePosts, CountryPosts, and LocationPosts components
import CityPosts from './components/CityPosts';
import StatePosts from './components/StatePosts';
import CountryPosts from './components/CountryPosts';
import LocationPosts from './components/LocationPosts';

// Import the Summary component
import Summary from './components/Summary';

// Import the About component
import About from './components/About';

// Import the new ProfileContext for managing profile data
import { ProfileProvider } from './contexts/ProfileContext';

// Import new components for ContactList functionality
import ContactList from './components/ContactList';
import ViewContactList from './components/ViewContactList';
import { ContactListProvider } from './contexts/ContactListContext';

// Import the new Bulletin component
import Bulletin from './components/Bulletin';

// Import the Bio component
import Bio from './components/Bio';

// Import the SharePost component
import SharePost from './components/SharePost';

// *** Import our Global Style ***
import { GlobalStyle } from './GlobalStyles';

function PageViewTracker() {
  let location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
    });
  }, [location]);

  return null;
}

function App() {
  const [activeItem, setActiveItem] = useState('Home');
  const [usernames, setUsernames] = useState({});
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const fetchAndSyncUsers = async () => {
      try {
        const usersCol = collection(db, 'users');
        const userSnapshot = await getDocs(usersCol);
        const users = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsernames(users.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.username }), {}));
      } catch (error) {
        console.error("Error fetching users from Firestore:", error);
      }
    };
    fetchAndSyncUsers();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsSidebarVisible(window.innerWidth > 768);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {/* Inject global style once at the top */}
      <GlobalStyle />

      <AuthProvider>
        <BlockProvider>
          <ClipsPostsProvider>
            <ClipsLikesProvider>
              <MessagesProvider>
                <ClipsReportsProvider>
                  <UsersProvider>
                    <UsernamesContext.Provider value={{ usernames, setUsernames }}>
                      <ActiveItemContext.Provider value={{ activeItem, setActiveItem }}>
                        <ProfileProvider>
                          <ContactListProvider>
                            <Router>
                              <TitleManager />
                              <PageViewTracker />
                              <div className="App">
                                <Navbar
                                  isSidebarVisible={isSidebarVisible}
                                  setIsSidebarVisible={setIsSidebarVisible}
                                />
                                <Container maxWidth="lg">
                                  <Grid container spacing={3}>
                                    <Grid item xs={12} md={8} lg={isLargeScreen ? 8 : 9}>
                                      <Routes>
                                        <Route path="/" element={<HomePage />} /> {/* HomePage route */}
                                        <Route path="/clipspostform" element={<ClipsPostForm />} />
                                        <Route path="/clips" element={<ClipsHomePage />} />
                                        <Route path="/clipsposts/:id" element={<ClipsPostPage />} />
                                        <Route path="/clipslikes" element={<ClipsLikesPage />} />
                                        <Route path="/clipsmyposts" element={<ClipsMyPostsPage />} />
                                        <Route path="/editclipspost/:id" element={<ClipsEditPostForm />} />
                                        <Route path="/clipsusersposts/:username" element={<ClipsUsersPosts />} />
                                        <Route path="/clipssearch" element={<ClipsSearchResultsPage />} />
                                        <Route path="/chat/:username" element={<ChatComponent />} />
                                        <Route path="/chat" element={<ChatWindow />} />
                                        <Route path="/clipscontactform" element={<ClipsContactForm />} />
                                        <Route
                                          path="/clips-admin-dashboard"
                                          element={
                                            <ProtectedRoute allowedRoles={['admin']}>
                                              <ClipsAdminDashboard />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route path="/info" element={<InfoPage />} />
                                        <Route path="/clips-view-reports" element={<ClipsViewReports />} />
                                        <Route path="/summary" element={<Summary />} />
                                        <Route path="/about" element={<About />} />
                                        <Route path="/categoryposts/:category" element={<CategoryPosts />} />
                                        <Route path="/cityposts/:city" element={<CityPosts />} />
                                        <Route path="/stateposts/:state" element={<StatePosts />} />
                                        <Route path="/countryposts/:country" element={<CountryPosts />} />
                                        <Route path="/locationposts/:location" element={<LocationPosts />} />
                                        <Route path="/contact-list/:postId" element={<ContactList />} />
                                        <Route path="/view-contact-list" element={<ViewContactList />} />
                                        <Route path="/bulletin" element={<Bulletin />} />
                                        <Route path="/bio" element={<Bio />} />
                                        <Route path="/trending" element={<Trending />} />
                                        <Route path="/newposts" element={<NewPosts />} />
                                        <Route path="/messages" element={<MessagesPage />} />
                                        <Route path="/login" element={<LoginPage />} />
                                        <Route path="/signup" element={<SignUpPage />} />
                                        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                                        <Route path="/explore" element={<ExplorePage />} />
                                        <Route
                                          path="/sharepost/:id"
                                          element={
                                            <SharePost url={`${window.location.origin}/clipsposts/:id`} />
                                          }
                                        />
                                      </Routes>
                                    </Grid>
                                    {isLargeScreen && <Grid item lg={1} />}
                                    <Grid item xs={12} md={4} lg={3}>
                                      <Sidebar
                                        isVisible={isSidebarVisible}
                                        setIsSidebarVisible={setIsSidebarVisible}
                                      />
                                    </Grid>
                                  </Grid>
                                </Container>
                                <Footer />
                              </div>
                            </Router>
                          </ContactListProvider>
                        </ProfileProvider>
                      </ActiveItemContext.Provider>
                    </UsernamesContext.Provider>
                  </UsersProvider>
                </ClipsReportsProvider>
              </MessagesProvider>
            </ClipsLikesProvider>
          </ClipsPostsProvider>
        </BlockProvider>
      </AuthProvider>
    </>
  );
}

export default App;
