/* Navbar.js */

import React, { useState, useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { FaSearch, FaUser, FaEnvelope, FaBars, FaHeart, FaTimes } from 'react-icons/fa';
import { IoCompassOutline } from 'react-icons/io5';
import { MdFiberNew } from 'react-icons/md';
import { BsFillPersonVcardFill } from 'react-icons/bs';
import './Navbar.css';

import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import ActiveItemContext from '../contexts/ActiveItemContext';
import { useMessages } from '../contexts/MessagesContext';

const NavBar = ({ isSidebarVisible, setIsSidebarVisible }) => {
  const { isLoggedIn, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { setActiveItem } = useContext(ActiveItemContext);
  const { fetchMessages, unreadMessagesCount } = useMessages();
  const [searchTerm, setSearchTerm] = useState("");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1180);

  useEffect(() => {
    const handleResize = () => {
      const isTabletSize = window.innerWidth <= 1180;
      setIsDesktop(!isTabletSize);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the correct state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isLoggedIn && currentUser) {
      fetchMessages();
    }
  }, [isLoggedIn, currentUser, fetchMessages]);

  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const executeSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/clipssearch?query=${encodeURIComponent(searchTerm.trim())}`);
    }
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
    }
  };

  const handleRestrictedClick = (path) => {
    if (!isLoggedIn) {
      setActiveItem('Login');
      navigate('/login');
    } else {
      navigate(path);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const renderSearchInput = () => (
    <div className="navbar__search">
      <input
        type="text"
        className="search-input"
        value={searchTerm}
        onChange={handleSearchInput}
        onKeyPress={handleSearchKeyPress}
        placeholder="Search for Posts"
      />
      <div className="search-icons">
        {searchTerm && (
          <FaTimes className="clear-search-icon" onClick={() => setSearchTerm('')} />
        )}
        <FaSearch className="search-icon" onClick={executeSearch} />
      </div>
    </div>
  );

  return (
    <Container id="navbar">
      {!isDesktop ? (
        // Mobile/Tablet Layout
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <div className="hamburger-menu" onClick={toggleSidebar}>
            <FaBars />
          </div>
          <div className="hyroclipse-logo">
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                // If the user is already on '/', scroll to top, else navigate
                if (location.pathname === '/') {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                  navigate('/');
                }
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/upscaled_hyroclipse_logo.png`}
                alt="HyroClipse Logo"
                className="navbar-hyroclipse-logo"
              />
            </a>
          </div>
          {renderSearchInput()}
        </div>
      ) : (
        // Desktop Layout
        <>
          <div className="navbar-left">
            {renderSearchInput()}
          </div>

          <div className="nav-icons-large">
            {/* 
              If user is already on /newposts, scroll to top; 
              otherwise, navigate('/newposts'). 
            */}
            <MdFiberNew
              className="navbar-icon navbar-icon-newposts"
              onClick={() => {
                if (location.pathname === '/newposts') {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                  navigate('/newposts');
                }
              }}
            />

            <BsFillPersonVcardFill 
              className="navbar-icon navbar-icon-bio" 
              onClick={() => navigate('/bio')} 
            />
            <IoCompassOutline 
              className="navbar-icon navbar-icon-explore" 
              onClick={() => navigate('/explore')} 
            />
          </div>

          <div className="nav-icons">
            <button
              onClick={() => handleRestrictedClick('/clipslikes')}
              className="navbar-item"
            >
              <FaHeart className="navbar-icon" />
              <span className="text">Likes</span>
            </button>
            <button
              onClick={() => handleRestrictedClick('/clipsmyposts')}
              className="navbar-item"
            >
              <FaUser className="navbar-icon" />
              <span className="text">MP</span>
            </button>
            <button
              onClick={() => handleRestrictedClick('/messages')}
              className="navbar-item"
              style={{ position: 'relative' }}
            >
              <FaEnvelope className="navbar-icon" />
              <span className="text">DM</span>
              {unreadMessagesCount > 0 && (
                <span className="unread-messages-count">{unreadMessagesCount}</span>
              )}
            </button>
          </div>
        </>
      )}
    </Container>
  );
};

export default NavBar;
