// NewPosts.js

import React, { useContext, useEffect, useMemo, useState } from 'react';
import ClipsPost from './ClipsPost';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { FaSpinner } from 'react-icons/fa';
import './HomePage.css';
import {
  Box,
  Fade,
  List,
  ListItem,
  Paper,
  Typography,
  IconButton,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityTracker from './VisibilityTracker';

const NewPosts = () => {
  // 1) Destructure 'error' from context as well
  const {
    posts,
    fetchMorePosts,
    loading,
    hasMore,
    error,
    deletePostById
  } = useContext(ClipsPostsContext);

  const navigate = useNavigate();
  const [randomPostsKey, setRandomPostsKey] = useState(0);
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  // Enhanced screen size checks for better responsiveness
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const isVerySmallScreen = useMediaQuery('(max-width: 480px)');
  const isIPhoneSESize = useMediaQuery('(max-width: 375px)');

  const postContainerStyle = useMemo(
    () => ({
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto 15px',
      padding: 0,
      boxSizing: 'border-box'
    }),
    []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Infinite scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 200 &&
        !loading &&
        hasMore
      ) {
        fetchMorePosts();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, fetchMorePosts]);

  // Sync clickedPosts with localStorage
  useEffect(() => {
    const handleStorage = () => {
      const saved = localStorage.getItem('clickedPosts');
      if (saved) {
        setClickedPosts(JSON.parse(saved));
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  // Randomly select 3 valid posts
  const randomPosts = React.useMemo(() => {
    const validPosts = posts.filter((post) => !post.deleted && !post.isReported);
    const shuffledPosts = [...validPosts].sort(() => Math.random() - 0.5);
    return shuffledPosts.slice(0, 3);
  }, [posts, randomPostsKey]);

  const handlePostClick = (postId) => {
    const updatedClickedPosts = { ...clickedPosts, [postId]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigate(`/clipsposts/${postId}`);
  };

  const handleRefresh = () => {
    setRandomPostsKey((prevKey) => prevKey + 1);
  };

  const handleDelete = async (postId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this post?');
    if (!confirmDelete) return;

    try {
      await deletePostById(postId);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  // ----------------------------------------------------------------------------------
  //  ADDITIONAL CHECKS for loading/error/empty. This prevents getting “stuck loading.”
  // ----------------------------------------------------------------------------------
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <FaSpinner className="loading-spinner" size={24} spin />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>
        {error}
      </div>
    );
  }

  if (!posts || posts.length === 0) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        No new posts found.
      </div>
    );
  }
  // ----------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2,
        pt: { xs: 10, sm: 12, md: 15, lg: 8, xl: 8 }
      }}
      className="posts"
    >
      {posts.map((post, index) => {
        // ADD THIS LOG:
        console.log('Rendering post in NewPosts:', post.id);

        return (
          <React.Fragment key={post.id}>
            <div className="post-container" style={postContainerStyle}>
              <ClipsPost
                {...post}
                onDelete={() => handleDelete(post.id)}
              />
            </div>

            {/* Insert "Discover Posts" Paper after each block of 3 posts, if you like */}
            {(index + 1) % 3 === 0 && index + 1 < posts.length && (
              <Fade in={true} timeout={800}>
                <Paper
                  elevation={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: { xs: 2, sm: 3, md: 4 },
                    pt: { xs: 2, sm: 3, md: 3 },
                    pb: { xs: 1, sm: 2, md: 3 },
                    mt: 4,
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto',
                    mb: 2,
                    borderRadius: '16px',
                    backgroundColor: '#fff',
                    overflow: 'hidden'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      mb: { xs: 1, sm: 2, md: 2 },
                      flexWrap: 'nowrap',
                      gap: { xs: 1, sm: 2 }
                    }}
                  >
                    <Typography
                      variant={isVerySmallScreen ? 'h5' : 'h4'}
                      align="left"
                      sx={{
                        fontWeight: '700',
                        color: '#1d1d1f',
                        lineHeight: 1.2,
                        fontSize: {
                          xs: '1.3rem',
                          sm: '1.5rem',
                          md: '1.8rem'
                        },
                        textAlign: 'left',
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        maxWidth: { xs: '70%', sm: '80%' }
                      }}
                    >
                      Discover Posts
                    </Typography>
                    <IconButton
                      onClick={handleRefresh}
                      sx={{
                        color: '#007aff',
                        padding: { xs: '6px', sm: '8px' },
                        backgroundColor: '#f5f5f7',
                        '&:hover': { backgroundColor: '#e8e8ed' },
                        transition: 'background-color 0.3s ease',
                        flexShrink: 0,
                        ml: 'auto'
                      }}
                    >
                      <RefreshIcon fontSize={isIPhoneSESize ? 'small' : 'medium'} />
                    </IconButton>
                  </Box>
                  <List
                    sx={{
                      width: '100%',
                      padding: 0,
                      mt: 0
                    }}
                  >
                    {randomPosts.map((rPost, idx) => (
                      <VisibilityTracker key={rPost.id} postId={rPost.id} isDiscoveryBox={true}>
                        <Fade in={true} timeout={500 + idx * 100} unmountOnExit>
                          <ListItem
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              padding: idx === 0 ? '8px 0 16px' : '12px 0 16px',
                              borderRadius: '8px',
                              '&:hover': { backgroundColor: 'transparent' }
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 0.5 }}>
                              <Typography
                                variant="body1"
                                component="span"
                                onClick={() => {
                                  const updatedClickedPosts = { ...clickedPosts, [rPost.id]: true };
                                  setClickedPosts(updatedClickedPosts);
                                  localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
                                  navigate(`/clipsposts/${rPost.id}`);
                                }}
                                sx={{
                                  color: clickedPosts[rPost.id] ? '#551A8B' : '#1a0dab',
                                  textDecoration: 'none',
                                  cursor: 'pointer',
                                  fontWeight: 'bold',
                                  '&:hover': { textDecoration: 'underline' },
                                  fontSize: '1.1rem',
                                  marginBottom: '2px'
                                }}
                              >
                                {rPost.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                sx={{ color: '#86868b', fontSize: '0.9rem' }}
                              >
                                {rPost.city && rPost.state
                                  ? `(${rPost.city}, ${rPost.state})`
                                  : rPost.city
                                  ? `(${rPost.city})`
                                  : rPost.state
                                  ? `(${rPost.state})`
                                  : ''}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#1d1d1f',
                                fontWeight: 400,
                                fontSize: '0.95rem',
                                mt: 0.5
                              }}
                            >
                              {rPost.summary ||
                                (rPost.description?.length > 100
                                  ? `${rPost.description.substring(0, 100)}...`
                                  : rPost.description)}
                            </Typography>
                          </ListItem>
                        </Fade>
                      </VisibilityTracker>
                    ))}
                  </List>
                </Paper>
              </Fade>
            )}
          </React.Fragment>
        );
      })}

      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <FaSpinner className="loading-spinner" size={24} spin />
        </div>
      )}
    </Box>
  );
};

export default NewPosts;
