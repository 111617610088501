// ClipsUsersPosts.js

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { FaUser } from 'react-icons/fa';
import { getAllDataFromDB } from '../utils/dbUtilities';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext'; // <-- import context
import './HomePage.css'; // Ensure this is imported for consistent styling

function ClipsUsersPosts() {
  const [userPosts, setUserPosts] = useState([]);
  const { username } = useParams();

  // CHANGED: we grab the deletePostById function from context
  const { deletePostById } = useContext(ClipsPostsContext);

  useEffect(() => {
    document.title = `${username}'s posts`;
    window.scrollTo(0, 0);

    async function fetchUserPosts() {
      try {
        const allPosts = await getAllDataFromDB('clipsPosts');

        const specificUserPosts = allPosts
          .filter((post) => post.author === username && !post.deleted)
          .map((post) => ({
            ...post,
            timestamp: post.timestamp?.toDate
              ? post.timestamp.toDate()
              : new Date(post.timestamp)
          }))
          .sort((a, b) => b.timestamp - a.timestamp); // Newest first

        setUserPosts(specificUserPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    }

    fetchUserPosts();
  }, [username]);

  // CHANGED: handle post deletion (same approach as in MyPostsPage/NewPosts)
  const handleDeletePost = async (postId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this post?');
    if (!confirmDelete) return;

    try {
      // 1) Mark the post as deleted in Firestore + global state
      await deletePostById(postId);

      // 2) Filter it out of local userPosts so it disappears
      setUserPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  return (
    <div className="posts">
      <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>
        <FaUser /> Posts by {username}
      </h1>

      {userPosts.length === 0 ? (
        <div className="no-posts-message" style={{ textAlign: 'center' }}>
          <p>No posts found for {username}</p>
        </div>
      ) : (
        userPosts.map((post) => (
          <div key={post.id} className="post-container">
            <ClipsPost
              {...post}
              // CHANGED: pass onDelete so <ClipsPost> can call handleDeletePost
              onDelete={() => handleDeletePost(post.id)}
            />
          </div>
        ))
      )}
    </div>
  );
}

export default ClipsUsersPosts;
