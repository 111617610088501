// Footer.js

import React, { useContext, useEffect, useState } from 'react';
import './Footer.css';
import { useNavigate, useLocation } from 'react-router-dom'; // <-- Import useLocation
import { IoHomeOutline, IoAddCircleOutline, IoCompassOutline } from 'react-icons/io5';
import { MdFiberNew } from 'react-icons/md';
import { useAuth } from '../contexts/AuthContext';
import ActiveItemContext from '../contexts/ActiveItemContext';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation(); // <-- We can check the current path
  const { isLoggedIn } = useAuth();
  const { setActiveItem } = useContext(ActiveItemContext);
  const [isVisible, setIsVisible] = useState(window.innerWidth <= 1180);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth <= 1180);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePostClick = (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      navigate('/login');
      setActiveItem('Login');
      return;
    }
    
    setActiveItem('Post');
    navigate('/clipspostform');
  };

  // NEW: separate click handlers for Home & New Posts
  const handleHomeClick = () => {
    setActiveItem('Home');
    if (location.pathname === '/') {
      // already on home, so scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  const handleNewPostsClick = () => {
    setActiveItem('New Posts');
    if (location.pathname === '/newposts') {
      // already on newposts, so scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/newposts');
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <footer className="app-footer">
      <div className="footer-icons">
        {/* Home Icon */}
        <div onClick={handleHomeClick} className="footer-icon">
          <IoHomeOutline />
        </div>

        {/* Post Icon */}
        <div onClick={handlePostClick} className="footer-icon">
          <IoAddCircleOutline />
        </div>

        {/* New Posts Icon */}
        <div onClick={handleNewPostsClick} className="footer-icon footer-icon--new">
          <MdFiberNew />
        </div>

        {/* Explore Icon */}
        <div onClick={() => {
          setActiveItem('Explore');
          navigate('/explore');
        }} className="footer-icon">
          <IoCompassOutline />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
