// HomePage.js

import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { useAuth } from '../contexts/AuthContext';
import './HomePage.css';
import {
  Box,
  Paper,
  List,
  ListItem,
  Typography,
  Fade,
  IconButton,
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IoTrendingUpOutline } from 'react-icons/io5';
import VisibilityTracker from './VisibilityTracker';
import { getTotalDiscoveryBoxImpressions } from '../utils/dbUtilities';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';

const HomePage = () => {
  const {
    posts,
    fetchMorePosts,
    loading: contextLoading, // renaming to avoid confusion
    hasMore,
    deletePostById
  } = useContext(ClipsPostsContext);

  const { user: authUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Track local “clicked” states in localStorage
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });
  const [clickedUserNames, setClickedUserNames] = useState(() => {
    const saved = localStorage.getItem('clickedUserNames');
    return saved ? JSON.parse(saved) : {};
  });

  // For random “Discover Posts” and “Discover People” refresh
  const [randomPostsKey, setRandomPostsKey] = useState(0);
  const [randomBiosKey, setRandomBiosKey] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // For storing aggregated user bios
  const [userBios, setUserBios] = useState({});
  const [totalImpressions, setTotalImpressions] = useState(0);

  // Media queries
  const isMobile = useMediaQuery('(max-width:768px)');
  const isSmallScreen = useMediaQuery('(max-width:1180px)');

  // Style for the main post container
  const postContainerStyle = useMemo(
    () => ({
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto 15px',
      padding: 0,
      boxSizing: 'border-box'
    }),
    []
  );

  // Delete a post
  const handleDelete = async (postId) => {
    const confirm = window.confirm('Are you sure you want to delete this post?');
    if (!confirm) return;
    try {
      await deletePostById(postId);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  // Fetch total “discovery box” impressions
  const fetchTotalImpressions = useCallback(async () => {
    try {
      const impressions = await getTotalDiscoveryBoxImpressions();
      setTotalImpressions((prevCount) =>
        impressions > prevCount ? impressions : prevCount
      );
    } catch (error) {
      console.error('Error fetching total impressions:', error);
    }
  }, []);

  // Scroll to top on mount or user change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [authUser]);

  // Periodically refresh total impressions
  useEffect(() => {
    fetchTotalImpressions();
    const intervalId = setInterval(() => {
      fetchTotalImpressions();
    }, 120000); // every 2 mins
    return () => clearInterval(intervalId);
  }, [fetchTotalImpressions]);

  // Keep local clicked states in sync if storage changes in another tab
  useEffect(() => {
    const handleStorage = () => {
      const savedPosts = localStorage.getItem('clickedPosts');
      const savedUserNames = localStorage.getItem('clickedUserNames');
      if (savedPosts) {
        const parsed = JSON.parse(savedPosts);
        if (JSON.stringify(parsed) !== JSON.stringify(clickedPosts)) {
          setClickedPosts(parsed);
        }
      }
      if (savedUserNames) {
        const parsedNames = JSON.parse(savedUserNames);
        if (JSON.stringify(parsedNames) !== JSON.stringify(clickedUserNames)) {
          setClickedUserNames(parsedNames);
        }
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [clickedPosts, clickedUserNames]);

  // ----------------------
  //  Filter home page posts from the global posts
  // ----------------------
  const homePagePosts = useMemo(() => {
    const validPosts = posts.filter(
      (p) => p.showOnHomePage && !p.deleted && !p.isReported
    );
    // Sort newest first
    validPosts.sort((a, b) => {
      const aTime = a.timestamp?.toDate
        ? a.timestamp.toDate().getTime()
        : new Date(a.timestamp || 0).getTime();
      const bTime = b.timestamp?.toDate
        ? b.timestamp.toDate().getTime()
        : new Date(b.timestamp || 0).getTime();
      return bTime - aTime;
    });
    return validPosts;
  }, [posts]);

  // Build aggregated user bios from the global `posts` array
  const aggregateRecentUserBios = useCallback((allPosts) => {
    const bios = {};
    allPosts
      .filter(
        (post) =>
          post.author &&
          post.bio &&
          post.bio.trim() !== '' &&
          !post.deleted &&
          !post.isReported
      )
      // newest first
      .sort((a, b) => {
        const dateA = a.timestamp?.toDate
          ? a.timestamp.toDate()
          : new Date(a.timestamp);
        const dateB = b.timestamp?.toDate
          ? b.timestamp.toDate()
          : new Date(b.timestamp);
        return dateB - dateA; // descending
      })
      .forEach((post) => {
        // If we haven't assigned a bio for this author yet, use this post
        if (!bios[post.author]) {
          bios[post.author] = {
            bio: post.bio,
            city: post.city || '',
            state: post.state || '',
            name: post.userName || ''
          };
        }
      });
    return bios;
  }, []);

  // Update userBios whenever `posts` changes
  useEffect(() => {
    if (posts && posts.length > 0) {
      setUserBios(aggregateRecentUserBios(posts));
    }
  }, [posts, aggregateRecentUserBios]);

  // Handle post click => single post page
  const handlePostClick = useCallback(
    (postId) => {
      const updatedClickedPosts = { ...clickedPosts, [postId]: true };
      setClickedPosts(updatedClickedPosts);
      localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
      navigate(`/clipsposts/${postId}`);
    },
    [clickedPosts, navigate]
  );

  // Handle user name click => that user’s posts
  const handleUserNameClick = useCallback(
    (userName) => {
      const updatedClickedUserNames = { ...clickedUserNames, [userName]: true };
      setClickedUserNames(updatedClickedUserNames);
      localStorage.setItem('clickedUserNames', JSON.stringify(updatedClickedUserNames));
      navigate(`/clipsusersposts/${userName}`);
    },
    [clickedUserNames, navigate]
  );

  // Refresh “Discover Posts”
  const handleRefresh = useCallback(() => {
    setIsInitialLoad(false);
    setRandomPostsKey((prev) => prev + 1);
  }, []);

  // Refresh “Discover People”
  const handleBioRefresh = useCallback(() => {
    // Attempt to fetch more from Firestore if we have more to load
    if (hasMore && !contextLoading) {
      fetchMorePosts();
    }
    setRandomBiosKey((prev) => prev + 1);
  }, [fetchMorePosts, hasMore, contextLoading]);

  // Helpers
  const truncateBio = useCallback((bio) => {
    const words = bio.split(' ');
    return words.length > 8 ? words.slice(0, 8).join(' ') + '...' : bio;
  }, []);

  const getLocationString = useCallback((city, state) => {
    if (city && state) return `(${city}, ${state})`;
    if (city) return `(${city})`;
    if (state) return `(${state})`;
    return '';
  }, []);

  // We’ll use the global “posts” array to find “discover posts”
  // (like the original code).
  const uniquePosts = useMemo(() => {
    if (!posts) return [];
    // filter out duplicates by ID
    return Array.from(new Map(posts.map((p) => [p.id, p])).values());
  }, [posts]);

  const discoverPosts = useMemo(() => {
    return uniquePosts.filter((post) => post.showInDiscoverBox);
  }, [uniquePosts]);

  // We only fill “randomDiscoverPosts” if user explicitly refreshes
  // (isInitialLoad = false), or if you prefer it to always show on first mount, remove the isInitialLoad check.
  const randomDiscoverPosts = useMemo(() => {
    if (!discoverPosts.length) return [];
    if (isInitialLoad) {
      return [];
    } else {
      const shuffled = [...discoverPosts]
        .filter((post) => !post.deleted && !post.isReported)
        .sort(() => Math.random() - 0.5);
      return shuffled.slice(0, Math.min(5, shuffled.length));
    }
  }, [discoverPosts, randomPostsKey, isInitialLoad]);

  // “Discover People” random userBios
  const randomUserBios = useMemo(() => {
    const entries = Object.entries(userBios).filter(
      ([author]) => !posts.find((p) => p.author === author && p.isReported)
    );

    // shuffle them
    for (let i = entries.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [entries[i], entries[j]] = [entries[j], entries[i]];
    }

    return entries.slice(0, Math.min(8, entries.length));
  }, [userBios, randomBiosKey, posts]);

  // If the global context is still loading from Firestore => show spinner
  if (contextLoading) {
    return (
      <Box
        sx={{
          height: '50vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // If context loaded but we literally have zero posts in `posts`
  if (posts && posts.length === 0) {
    return (
      <Typography variant="body1" sx={{ textAlign: 'center', mt: 3 }}>
        No posts found yet.
      </Typography>
    );
  }

  // Now we can safely show the actual content
  return (
    <Box sx={{ pb: 4 }}>
      <div className="posts">
        {/* If no homePagePosts specifically, just skip. */}
        {homePagePosts.length === 0 ? (
          <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>
            No home page posts yet.
          </Typography>
        ) : (
          (() => {
            // The first “newest” post:
            const [firstPost, ...restPosts] = homePagePosts;

            return (
              <>
                {/* ====== The first (newest) post on top ====== */}
                <div key={firstPost.id} className="post-container" style={postContainerStyle}>
                  <ClipsPost
                    {...firstPost}
                    onDelete={() => handleDelete(firstPost.id)}
                  />
                </div>

                {/* ========== “Discover Posts” Box (below the first post) ========== */}
                <Fade in={true} timeout={800} unmountOnExit>
                  <Paper
                    elevation={3}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      p: 4,
                      mt: 4,
                      width: '100%',
                      maxWidth: '800px',
                      margin: '0 auto',
                      mb: 2,
                      borderRadius: '16px',
                      backgroundColor: '#fff',
                      overflow: 'hidden'
                    }}
                  >
                    <Box sx={{ width: '100%', mb: 2 }}>
                      {!isSmallScreen ? (
                        <Box
                          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: '700',
                                color: '#1d1d1f',
                                lineHeight: 1.2
                              }}
                            >
                              Discover Posts
                            </Typography>
                            <IconButton
                              onClick={handleRefresh}
                              sx={{
                                color: '#007aff',
                                padding: '8px',
                                backgroundColor: '#f5f5f7',
                                '&:hover': { backgroundColor: '#e8e8ed' },
                                transition: 'background-color 0.3s ease'
                              }}
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: '400',
                              color: '#86868b',
                              mt: 1,
                              textAlign: 'left'
                            }}
                          >
                            Explore Projects, Ventures, Causes &amp; Ideas
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ width: '100%' }}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: '700',
                              color: '#1d1d1f',
                              lineHeight: 1.2,
                              mb: 1,
                              textAlign: 'left'
                            }}
                          >
                            Discover Posts
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: '400',
                              color: '#86868b',
                              mb: 2,
                              textAlign: 'left'
                            }}
                          >
                            Explore Projects, Ventures, Causes &amp; Ideas
                          </Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <IconButton
                              onClick={handleRefresh}
                              sx={{
                                color: '#007aff',
                                padding: '16px',
                                backgroundColor: '#f5f5f7',
                                '&:hover': { backgroundColor: '#e8e8ed' },
                                transition: 'background-color 0.3s ease'
                              }}
                            >
                              <RefreshIcon sx={{ fontSize: '48px' }} />
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <List sx={{ width: '100%', padding: 0 }}>
                      {randomDiscoverPosts.map((post, index) => (
                        <VisibilityTracker key={post.id} postId={post.id} isDiscoveryBox={true}>
                          <Fade in={true} timeout={500 + index * 100} unmountOnExit>
                            <ListItem
                              component="div"
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                padding: '16px 0',
                                borderRadius: '8px',
                                '&:hover': { backgroundColor: 'transparent' }
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                  mb: 1
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  component="span"
                                  onClick={() => handlePostClick(post.id)}
                                  sx={{
                                    color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    '&:hover': { textDecoration: 'underline' },
                                    fontSize: '1.1rem',
                                    marginBottom: '4px'
                                  }}
                                >
                                  {post.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  component="span"
                                  sx={{ color: '#86868b', fontSize: '0.9rem' }}
                                >
                                  {getLocationString(post.city, post.state)}
                                </Typography>
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}
                              >
                                {post.summary ||
                                  (post.description?.length > 100
                                    ? `${post.description.substring(0, 100)}...`
                                    : post.description)}
                              </Typography>
                            </ListItem>
                          </Fade>
                        </VisibilityTracker>
                      ))}
                    </List>

                    {/* “Total Post Views” + “Trending Posts” */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        mt: 4,
                        flexDirection: 'column'
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#333',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: '#fff',
                            color: '#000',
                            fontFamily: 'Bebas Neue, sans-serif',
                            fontSize: '1.5rem',
                            padding: '5px 10px',
                            border: '2px solid #000',
                            borderRadius: '8px',
                            marginRight: '10px'
                          }}
                        >
                          {totalImpressions.toLocaleString()}
                        </span>
                        Total Post Views
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 'auto',
                          mt: 2,
                          cursor: 'pointer',
                          backgroundColor: '#f5f5f7',
                          padding: '8px 16px',
                          borderRadius: '8px',
                          transition: 'background-color 0.3s ease',
                          '&:hover': {
                            backgroundColor: '#e8e8ed'
                          },
                          borderWidth: '2px',
                          borderColor: '#007aff',
                          borderStyle: 'solid'
                        }}
                        onClick={() => navigate('/trending')}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#1a0dab' }}
                        >
                          <IoTrendingUpOutline size={24} />
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 500,
                              color: '#1a0dab'
                            }}
                          >
                            View Trending Posts
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Fade>

                {/* ====== The remaining home posts ====== */}
                {restPosts.map((post) => (
                  <div key={post.id} className="post-container" style={postContainerStyle}>
                    <ClipsPost
                      {...post}
                      onDelete={() => handleDelete(post.id)}
                    />
                  </div>
                ))}
              </>
            );
          })()
        )}

        {/* ========== “Discover People” Box (at the bottom) ========== */}
        <Fade in={true} timeout={800} unmountOnExit>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 4,
              mt: 4,
              mb: 4,
              width: '100%',
              maxWidth: '800px',
              margin: '0 auto 2rem auto',
              borderRadius: '16px',
              backgroundColor: '#fff',
              overflow: 'hidden'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                mb: 4
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: '700',
                  color: '#1d1d1f',
                  textAlign: isSmallScreen ? 'left' : 'inherit',
                  '@media (max-width: 768px)': {
                    fontSize: '1.5rem'
                  }
                }}
              >
                Discover People
              </Typography>
              <IconButton
                onClick={handleBioRefresh}
                sx={{
                  color: '#007aff',
                  backgroundColor: '#f5f5f7',
                  '&:hover': { backgroundColor: '#e8e8ed' },
                  transition: 'background-color 0.3s ease'
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Box>

            {Object.keys(userBios).length === 0 ? (
              <Typography variant="body1" sx={{ color: '#86868b' }}>
                No user profiles available.
              </Typography>
            ) : (
              <List sx={{ width: '100%', padding: 0 }}>
                {randomUserBios.map(([author, { bio, city, state, name }], index) => (
                  <Fade
                    in={true}
                    timeout={500 + index * 100}
                    key={`${author}-${index}`}
                    unmountOnExit
                  >
                    <ListItem
                      component="div"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '16px 0',
                        borderRadius: '8px',
                        '&:hover': { backgroundColor: 'transparent' }
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                        <Typography
                          variant="body1"
                          component="span"
                          onClick={() => handleUserNameClick(author)}
                          sx={{
                            color: clickedUserNames[author] ? '#551A8B' : '#1a0dab',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            '&:hover': { textDecoration: 'underline' },
                            fontSize: '1.1rem',
                            marginBottom: '4px'
                          }}
                        >
                          {name || author}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ color: '#86868b', fontSize: '0.9rem' }}
                        >
                          {getLocationString(city, state)}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}
                      >
                        {truncateBio(bio)}
                      </Typography>
                    </ListItem>
                  </Fade>
                ))}
              </List>
            )}
          </Paper>
        </Fade>
      </div>
    </Box>
  );
};

export default HomePage;
