// Bulletin.js

import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, List, ListItem, Paper, Fade, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { timeAgo } from '../utils/timeFormatter';
import VisibilityTracker from './VisibilityTracker';
import './Bulletin.css';

const Bulletin = () => {
  const { posts, fetchMorePosts, loading, hasMore } = useContext(ClipsPostsContext);
  const navigate = useNavigate();

  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  // Scroll top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Infinite scroll: load more when near bottom
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 200 &&
        !loading &&
        hasMore
      ) {
        fetchMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, fetchMorePosts]);

  // Only update clickedPosts if new data is different
  useEffect(() => {
    const handleStorage = () => {
      const savedPosts = localStorage.getItem('clickedPosts');
      if (savedPosts) {
        const parsed = JSON.parse(savedPosts);
        if (JSON.stringify(parsed) !== JSON.stringify(clickedPosts)) {
          setClickedPosts(parsed);
        }
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [clickedPosts]);

  const uniquePosts = useMemo(() => {
    if (!posts) return [];
    return Array.from(new Map(posts.map((post) => [post.id, post])).values());
  }, [posts]);

  const validPosts = useMemo(() => {
    return uniquePosts.filter((p) => !p.deleted && !p.isReported);
  }, [uniquePosts]);

  const handlePostClick = useCallback(
    (postId) => {
      const updated = { ...clickedPosts, [postId]: true };
      setClickedPosts(updated);
      localStorage.setItem('clickedPosts', JSON.stringify(updated));
      navigate(`/clipsposts/${postId}`);
    },
    [clickedPosts, navigate]
  );

  const navigateToCityPosts = (city) => navigate(`/cityposts/${city}`);
  const navigateToStatePosts = (state) => navigate(`/stateposts/${state}`);

  const renderCityState = (postId, city, state) => {
    if (!city && !state) return null;
    const linkStyle = {
      color: clickedPosts[postId] ? '#551A8B' : '#1a0dab',
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: 'bold',
    };

    return (
      <span>
        {' ('}
        {city && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              navigateToCityPosts(city);
            }}
            style={linkStyle}
          >
            {city}
          </span>
        )}
        {city && state && ', '}
        {state && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              navigateToStatePosts(state);
            }}
            style={linkStyle}
          >
            {state}
          </span>
        )}
        {')'}
      </span>
    );
  };

  const getSummaryOrDescription = (post) => {
    if (post.summary) return post.summary;
    if (post.description) {
      return post.description.length > 100
        ? `${post.description.substring(0, 100)}...`
        : post.description;
    }
    return '';
  };

  return (
    <div className="posts">
      <Fade in={true} timeout={800} unmountOnExit>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mt: 4,
            width: '100%',
            maxWidth: '100%',
            mb: 2,
            borderRadius: '16px',
            backgroundColor: '#fff',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ width: '100%', textAlign: 'left' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: '700', color: '#1d1d1f', lineHeight: 1.2 }}
            >
              Bulletin
            </Typography>
          </Box>

          <Divider sx={{ width: '100%', my: 2 }} />

          {validPosts.length === 0 ? (
            <Typography variant="body1" sx={{ color: '#86868b' }}>
              No posts available.
            </Typography>
          ) : (
            <List sx={{ width: '100%', padding: 0 }}>
              {validPosts.map((post, index) => (
                <VisibilityTracker key={post.id} postId={post.id} isDiscoveryBox={true}>
                  <Fade in={true} timeout={500 + index * 100} unmountOnExit>
                    <ListItem
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '16px 0',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '1rem' }}
                      >
                        <Box
                          component="span"
                          onClick={() => handlePostClick(post.id)}
                          sx={{
                            color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            '&:hover': { textDecoration: 'underline' },
                            marginRight: '6px',
                          }}
                        >
                          {post.title}
                        </Box>
                        {renderCityState(post.id, post.city, post.state)}:{' '}
                        {getSummaryOrDescription(post)}
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          fontSize: '0.85rem',
                          mt: 0.5,
                        }}
                      >
                        {post.timestamp?.toDate
                          ? timeAgo(post.timestamp.toDate())
                          : 'No timestamp'}
                      </Typography>

                      {index < validPosts.length - 1 && (
                        <Divider sx={{ mt: 2, width: '100%' }} />
                      )}
                    </ListItem>
                  </Fade>
                </VisibilityTracker>
              ))}
            </List>
          )}
        </Paper>
      </Fade>

      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <span>Loading...</span>
        </div>
      )}
    </div>
  );
};

export default Bulletin;
