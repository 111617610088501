// ClipsEditPostForm.js

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { useAuth } from '../contexts/AuthContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { IoMdCloudUpload, IoMdImages } from 'react-icons/io';
import { FaInstagram, FaTwitter, FaTiktok, FaYoutube, FaFacebook } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import CloseButton from './CloseButton';
import { uploadImageToStorage, updatePostSummary } from '../utils/dbUtilities';
import heic2any from 'heic2any';
import './PostForm.css';
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';

// ========== Constants & Utilities ==========
const MAX_FILE_SIZE = 5 * 1024 * 1024;
const invalidFileTypes = ['video/mp4', 'audio/wav', 'image/gif', 'video/x-ms-wmv'];

const checkFileValidity = (file, setError, setProcessing, setProgress) => {
  if (invalidFileTypes.includes(file.type)) {
    const errorMsg = 'Invalid file type. MP4, WAV, GIF, and WMV files are not allowed.';
    setError(errorMsg);
    setProcessing(false);
    setProgress(0);
    return false;
  }

  if (file.size > MAX_FILE_SIZE) {
    const errorMsg = 'File size exceeds 5 MB limit.';
    setError(errorMsg);
    setProcessing(false);
    setProgress(0);
    return false;
  }

  setError('');
  return true;
};

const compressImage = (file, setProgress) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob(
        (blob) => {
          setProgress(70);
          resolve(blob);
        },
        'image/jpeg',
        0.5
      );
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
};

const convertImageToJPG = async (file, setProgress) => {
  if (file.type === 'image/heic') {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.92
      });
      setProgress(50);
      return convertedBlob;
    } catch (error) {
      console.error('Error converting image:', error);
      throw error;
    }
  } else {
    return compressImage(file, setProgress);
  }
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop, circular = false) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  // Center image on canvas
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  if (circular) {
    ctx.globalCompositeOperation = 'destination-in';
    ctx.beginPath();
    ctx.arc(
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2,
      0,
      2 * Math.PI,
      true
    );
    ctx.fill();
  }

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
};

// ========== MyDropzone for main post image ==========
function MyDropzone({
  onImageUpload,
  setIsImageProcessing,
  setProgress,
  setUploadError,
  initialImage,
  onCropperOpen
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(initialImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if (initialImage) {
      setCroppedImagePreview(initialImage);
    }
  }, [initialImage]);

  const onCropComplete = useCallback((_, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  useEffect(() => {
    // If editing (selectedImage && no cropped preview), let parent know the cropper is open
    if (selectedImage && !croppedImagePreview) {
      onCropperOpen(true);
    } else {
      onCropperOpen(false);
    }
  }, [selectedImage, croppedImagePreview, onCropperOpen]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImage);
      setCroppedImagePreview(croppedImageUrl);
      setSelectedImage(null);
      onImageUpload(croppedImage);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setCroppedImagePreview(initialImage);
    setOriginalImage(null);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setSelectedImage(croppedImagePreview);
    setCroppedImagePreview(null);
  };

  const handleRemove = () => {
    setSelectedImage(null);
    setCroppedImagePreview(null);
    setOriginalImage(null);
    onImageUpload(null);
  };

  const onDrop = async (acceptedFiles) => {
    setIsImageProcessing(true);
    setProgress(5);
    const file = acceptedFiles[0];

    if (!checkFileValidity(file, setUploadError, setIsImageProcessing, setProgress)) {
      return;
    }

    const imageBlob =
      file.type === 'image/heic'
        ? await convertImageToJPG(file, setProgress)
        : await compressImage(file, setProgress);

    const imageUrl = URL.createObjectURL(imageBlob);
    setOriginalImage(imageUrl);
    setSelectedImage(imageUrl);
    setCroppedImagePreview(null);
    setProgress(80);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  });

  return (
    <div className="image-upload-section">
      {!selectedImage && !croppedImagePreview && (
        <div {...getRootProps()} className="drag-drop-zone">
          <input {...getInputProps()} />
          <IoMdCloudUpload size={50} className="upload-icon" />
          <p>Drag & Drop or Click Here to Upload Image (5 MB Size Limit)</p>
          <IoMdImages size={50} className="images-icon" />
        </div>
      )}

      {croppedImagePreview && (
        <div className="image-preview-container">
          <img
            src={croppedImagePreview}
            alt="Cropped"
            className="uploaded-image-preview"
          />
          <button onClick={handleRemove} className="remove-image-icon">
            X
          </button>
          <button onClick={handleEdit} className="edit-button">
            Edit
          </button>
        </div>
      )}

      {selectedImage && !croppedImagePreview && (
        <div className="cropper-container">
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="rect"
            showGrid={false}
            style={{
              containerStyle: {
                position: 'relative',
                width: '100%',
                height: '400px'
              },
              cropAreaStyle: { border: '2px solid white' },
              mediaStyle: { transform: 'translateZ(0)' }
            }}
          />
          <div className="cropper-toolbar">
            <button type="button" onClick={handleSave} className="save-button">
              Save
            </button>
            <button type="button" onClick={handleCancel} className="cancel-button">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

// ========== ProfileImageDropzone for the user's profile image ==========
function ProfileImageDropzone({
  onImageUpload,
  setIsImageProcessing,
  setProgress,
  setUploadError,
  initialImage,
  onCropperOpen
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(initialImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if (initialImage) {
      setCroppedImagePreview(initialImage);
    }
  }, [initialImage]);

  const onCropComplete = useCallback((_, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  useEffect(() => {
    if (selectedImage && !croppedImagePreview) {
      onCropperOpen(true);
    } else {
      onCropperOpen(false);
    }
  }, [selectedImage, croppedImagePreview, onCropperOpen]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels, true);
      const croppedImageUrl = URL.createObjectURL(croppedImage);
      setCroppedImagePreview(croppedImageUrl);
      setSelectedImage(null);
      onImageUpload(croppedImage);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setCroppedImagePreview(initialImage);
    setOriginalImage(null);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setSelectedImage(croppedImagePreview);
    setCroppedImagePreview(null);
  };

  const handleRemove = () => {
    setSelectedImage(null);
    setCroppedImagePreview(null);
    setOriginalImage(null);
    onImageUpload(null);
  };

  const onDrop = async (acceptedFiles) => {
    setIsImageProcessing(true);
    setProgress(5);
    const file = acceptedFiles[0];

    if (!checkFileValidity(file, setUploadError, setIsImageProcessing, setProgress)) {
      return;
    }

    const imageBlob =
      file.type === 'image/heic'
        ? await convertImageToJPG(file, setProgress)
        : await compressImage(file, setProgress);

    const imageUrl = URL.createObjectURL(imageBlob);
    setOriginalImage(imageUrl);
    setSelectedImage(imageUrl);
    setCroppedImagePreview(null);
    setProgress(80);
  };

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*', onDrop });

  return (
    <div className="image-upload-section profile-image">
      {!selectedImage && !croppedImagePreview && (
        <div {...getRootProps()} className="drag-drop-zone circular">
          <input {...getInputProps()} />
          <IoMdCloudUpload size={50} className="upload-icon" />
          <p>Upload Profile Image (5 MB Size Limit)</p>
          <IoMdImages size={50} className="images-icon" />
        </div>
      )}

      {croppedImagePreview && (
        <div className="image-preview-container circular">
          <img
            src={croppedImagePreview}
            alt="Cropped"
            className="uploaded-image-preview"
          />
          <button onClick={handleRemove} className="remove-image-icon">
            X
          </button>
          <button onClick={handleEdit} className="edit-button">
            Edit
          </button>
        </div>
      )}

      {selectedImage && !croppedImagePreview && (
        <div className="cropper-wrapper circular">
          <div className="cropper-container circular">
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
              showGrid={false}
              style={{
                containerStyle: { width: '100%', height: '300px', position: 'relative' },
                cropAreaStyle: { border: '2px solid white', borderRadius: '50%' },
                mediaStyle: { transform: 'translateZ(0)' }
              }}
            />
          </div>
          <div className="cropper-toolbar">
            <button type="button" onClick={handleSave} className="save-button">
              Save
            </button>
            <button type="button" onClick={handleCancel} className="cancel-button">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

// ========== ClipsEditPostForm Component ==========
const ClipsEditPostForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, loading } = useAuth();
  const { updateSinglePost, updatePostInDB } = useContext(ClipsPostsContext);

  // Post state
  const [post, setPost] = useState(null);

  // Image states
  const [uploadedImageBlob, setUploadedImageBlob] = useState(null);
  const [profileImageBlob, setProfileImageBlob] = useState(null);
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const [profileImageProcessing, setProfileImageProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [profileImageProgress, setProfileImageProgress] = useState(0);
  const [uploadError, setUploadError] = useState('');
  const [profileImageError, setProfileImageError] = useState('');

  // Cropper open states
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [isProfileCropperOpen, setIsProfileCropperOpen] = useState(false);

  // Form fields
  const [nickname, setNickname] = useState('');
  const [description, setDescription] = useState('');
  const [city, setCity] = useState('');
  const [stateVal, setStateVal] = useState('');
  const [country, setCountry] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [link, setLink] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [youtube, setYoutube] = useState('');
  const [facebook, setFacebook] = useState('');
  const [email, setEmail] = useState('');
  const [bio, setBio] = useState(''); // optional
  const [userName, setUserName] = useState(''); // optional

  // Word counts
  const [wordCount, setWordCount] = useState(0);
  const [bioWordCount, setBioWordCount] = useState(0);

  // Categories & goals
  const [categories, setCategories] = useState([]);
  const [goals, setGoals] = useState([]);

  // Validation
  const [fieldErrors, setFieldErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  // Post images
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [postImagePreview, setPostImagePreview] = useState(null);

  // NEW top-level error for missing/invalid fields
  const [generalError, setGeneralError] = useState('');

  // Category & goal arrays
  const sortedCategories = [
    'Animals',
    'Arts',
    'Beauty & Personal Care',
    'Charity',
    'Children',
    'Collectors',
    'Comedy',
    'Coming Soon',
    'Commmunity Outreach',
    'Community Organizing',
    'Education',
    'Entertainment',
    'Events',
    'Exercise & Fitness',
    'Fashion & Clothing',
    'Food',
    'FYI',
    'Gaming',
    'Health',
    'Home & Living',
    'Idea',
    'Launch',
    'Media',
    'Money & Finance',
    'Music',
    'New Project',
    'New Business',
    'News & Current Events',
    'Productivity',
    'Social',
    'Social Media',
    'Spiritual',
    'Sports',
    'Startups',
    'Tech',
    'Test Run',
    'Toys',
    'Travel',
    'TV & Film',
    'Vintage',
    'Other'
  ].sort();

  const sortedGoals = [
    'Build Awareness',
    'Gain New Followers',
    'Constructive Feedback',
    'Increase Sales',
    'Going Viral',
    'Fundraising'
  ];

  // 1) Wait for Auth
  useEffect(() => {
    if (!loading && !currentUser) {
      navigate('/login');
    }
  }, [loading, currentUser, navigate]);

  // 2) Scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Word Count helpers
  const updateWordCount = (text) => {
    const words = text.split(/\s+/).filter((w) => w.length > 0);
    setWordCount(words.length);
  };
  const updateBioWordCount = (text) => {
    const words = text.split(/\s+/).filter((w) => w.length > 0);
    setBioWordCount(words.length);
  };

  // 3) Fetch Post
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const docRef = doc(db, 'clipsPosts', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const postData = docSnap.data();
          if (postData.userId !== currentUser.uid) {
            console.log('Not authorized to edit');
            navigate('/');
            return;
          }
          setPost(postData);

          // Fill form fields
          setNickname(postData.title || '');
          setDescription(postData.description || '');
          setCity(postData.city || '');
          setStateVal(postData.state || '');
          setCountry(postData.country || '');
          setUserLocation(postData.location || '');
          setLink(postData.link || '');
          setInstagram(postData.instagram || '');
          setTwitter(postData.twitter || '');
          setTiktok(postData.tiktok || '');
          setYoutube(postData.youtube || '');
          setFacebook(postData.facebook || '');
          setCategories(postData.categories || []);
          setGoals(postData.goals || []);
          setEmail(postData.email || '');
          setUserName(postData.userName || '');
          setBio(postData.bio || '');
          setProfileImagePreview(postData.profileImageUrl || null);
          setPostImagePreview(postData.imageUrl || null);

          updateWordCount(postData.description || '');
          updateBioWordCount(postData.bio || '');
        } else {
          console.log('No such document!');
          navigate('/');
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        navigate('/');
      }
    };

    if (!loading && currentUser?.uid) {
      fetchPost();
    }
  }, [id, loading, currentUser, navigate]);

  // 4) Possibly load the most recent bio
  useEffect(() => {
    const loadMostRecentBio = async () => {
      try {
        const postsRef = collection(db, 'clipsPosts');
        const q = query(
          postsRef,
          where('userId', '==', currentUser.uid),
          orderBy('timestamp', 'desc'),
          limit(1)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const lastPost = querySnapshot.docs[0].data();
          if (lastPost.bio) {
            setBio(lastPost.bio);
            updateBioWordCount(lastPost.bio);
          }
          if (lastPost.userName) {
            setUserName(lastPost.userName);
          }
          if (lastPost.profileImageUrl) {
            setProfileImagePreview(lastPost.profileImageUrl);
          }
        }
      } catch (error) {
        console.error('Error loading bio:', error);
      }
    };

    if (post && !post.bio && currentUser) {
      loadMostRecentBio();
    }
  }, [post, currentUser]);

  // 5) If AI bio from location.state
  useEffect(() => {
    const generatedBio = location.state?.generatedBio;
    if (generatedBio) {
      setBio(generatedBio);
      updateBioWordCount(generatedBio);
    }
  }, [location.state?.generatedBio]);

  // 6) Possibly load from localStorage
  useEffect(() => {
    const savedBio = localStorage.getItem('bio');
    const savedUserName = localStorage.getItem('userName');
    const savedProfileImageUrl = localStorage.getItem('profileImageUrl');

    if (post && !post.bio && savedBio) {
      setBio(savedBio);
    }
    if (post && !post.userName && savedUserName) {
      setUserName(savedUserName);
    }
    if (post && !post.profileImageUrl && savedProfileImageUrl) {
      setProfileImagePreview(savedProfileImageUrl);
    }
  }, [post]);

  // Goals in localStorage
  useEffect(() => {
    const storedGoals = JSON.parse(localStorage.getItem('editGoals')) || [];
    if (!goals.length && storedGoals.length) {
      setGoals(storedGoals);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('editGoals', JSON.stringify(goals));
  }, [goals]);

  // Main & profile images
  const handleImageUpload = (imageBlob) => {
    setUploadedImageBlob(imageBlob);
    setIsImageProcessing(false);
    // If user removed the image (imageBlob === null), set preview to null
    if (imageBlob) {
      setPostImagePreview(URL.createObjectURL(imageBlob));
    } else {
      setPostImagePreview(null);
    }
  };

  const handleProfileImageUpload = async (imageBlob) => {
    setProfileImageBlob(imageBlob);
    setProfileImageProcessing(false);

    if (imageBlob) {
      const imageUrl = await uploadImageToStorage(
        imageBlob,
        `profileImages/${currentUser.uid}_${new Date().toISOString()}`
      );
      setProfileImagePreview(imageUrl);
      localStorage.setItem('profileImageUrl', imageUrl);
    } else {
      setProfileImagePreview(null);
      localStorage.removeItem('profileImageUrl');
    }
  };

  // Change handlers
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    updateWordCount(e.target.value);
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
    updateBioWordCount(e.target.value);
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCategories((prev) => [...prev, value]);
    } else {
      setCategories((prev) => prev.filter((cat) => cat !== value));
    }
  };

  // Basic file validation
  const validateFileType = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];
    if (!validTypes.includes(file.type)) {
      setUploadError('Invalid file type. Only JPEG, PNG, HEIC, and HEIF images are allowed.');
      return false;
    }
    return true;
  };

  // ========== The "Update Post" Submission ==========
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setShowErrors(true);

    // Clear any top-level error
    setGeneralError('');

    const form = event.target;
    const nicknameVal = form['nickname'].value.trim();
    const descriptionVal = form['description'].value.trim();
    const cityVal = form['city'].value.trim();
    const stateVal2 = form['state'].value.trim();
    const countryVal = form['country'].value.trim();
    const locationValue = form['location'].value;
    const linkVal = form['link'].value.trim();
    const instagramVal = form['instagram'].value.trim();
    const twitterVal = form['twitter'].value.trim();
    const tiktokVal = form['tiktok'].value.trim();
    const youtubeVal = form['youtube'].value.trim();
    const facebookVal = form['facebook'].value.trim();
    const bioVal = form['bio']?.value.trim(); // optional
    const userNameVal = form['userName']?.value.trim(); // optional
    const emailVal = form['email']?.value.trim();

    let hasErrors = false;
    const newFieldErrors = {};

    // ~~~~~ Field-level checks ~~~~~
    if (!nicknameVal) {
      newFieldErrors.nickname = 'Name is required.';
      hasErrors = true;
    }

    if (!descriptionVal) {
      newFieldErrors.description = 'Description is required.';
      hasErrors = true;
    }

    if (categories.length === 0) {
      newFieldErrors.categories = 'Please select at least one category.';
      hasErrors = true;
    }

    if (goals.length === 0) {
      newFieldErrors.goals = 'Please select at least one goal.';
      hasErrors = true;
    }

    if (!locationValue) {
      newFieldErrors.location = 'Please select a location.';
      hasErrors = true;
    }

    if (!cityVal) {
      newFieldErrors.city = 'City is required.';
      hasErrors = true;
    }

    if (!countryVal) {
      newFieldErrors.country = 'Country is required.';
      hasErrors = true;
    }

    if (wordCount > 40) {
      newFieldErrors.description = 'Description cannot exceed 40 words.';
      hasErrors = true;
    }

    // Make bio optional; only enforce word limit if present
    if (bioVal && bioWordCount > 40) {
      newFieldErrors.bio = 'Bio cannot exceed 40 words.';
      hasErrors = true;
    }

    // Validate link
    if (linkVal && !/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(linkVal)) {
      newFieldErrors.link = 'Please enter a valid URL';
      hasErrors = true;
    }

    // Validate email
    if (emailVal && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailVal)) {
      newFieldErrors.email = 'Please enter a valid email address.';
      hasErrors = true;
    }

    setFieldErrors(newFieldErrors);

    if (hasErrors) {
      // Show the top-level error
      setGeneralError('One or more required fields are missing or invalid.');
      setIsSubmitting(false);
      return;
    }

    // If user uploaded a new main image, validate
    if (uploadedImageBlob && !validateFileType(uploadedImageBlob)) {
      setIsSubmitting(false);
      return;
    }

    try {
      // =========== Main Image Handling ===========
      let imageDownloadUrl = post.imageUrl;
      if (uploadedImageBlob) {
        // If user provided a new image
        imageDownloadUrl = await uploadImageToStorage(
          uploadedImageBlob,
          `images/${currentUser.uid}_${new Date().toISOString()}`
        );
      } else if (postImagePreview === null) {
        // If the user specifically removed the image
        imageDownloadUrl = null;
      }

      // =========== Profile Image Handling ===========
      let profileImageDownloadUrl = post.profileImageUrl;
      if (profileImageBlob) {
        profileImageDownloadUrl = await uploadImageToStorage(
          profileImageBlob,
          `profileImages/${currentUser.uid}_${new Date().toISOString()}`
        );
      } else if (profileImagePreview === null) {
        // If the user removed their profile image
        profileImageDownloadUrl = null;
      }

      // Save or remove from localStorage if you wish
      localStorage.setItem('bio', bioVal || '');
      localStorage.setItem('userName', userNameVal || '');
      if (profileImageDownloadUrl) {
        localStorage.setItem('profileImageUrl', profileImageDownloadUrl);
      } else {
        localStorage.removeItem('profileImageUrl');
      }

      // Final post data
      const updatedPostData = {
        title: nicknameVal,
        description: descriptionVal,
        city: cityVal,
        state: stateVal2,
        country: countryVal,
        location: userLocation,
        categories,
        goals,
        link: linkVal,
        instagram: instagramVal,
        twitter: twitterVal,
        tiktok: tiktokVal,
        youtube: youtubeVal,
        facebook: facebookVal,
        email: emailVal || null,
        bio: bioVal || '', // optional
        profileImageUrl: profileImageDownloadUrl || null,
        imageUrl: imageDownloadUrl,
        userName: userNameVal || '', // optional
        lastEdited: new Date().toISOString()
      };

      // Update in Firestore
      const updatedPost = await updatePostInDB('clipsPosts', id, updatedPostData);
      if (!updatedPost) {
        throw new Error('Failed to update post');
      }

      // Prepare final updated post (we'll attach summary if we get it)
      const updatedPostWithSummary = { ...updatedPost };

      // === Attempt to update summary if description changed ===
      if (descriptionVal !== post.description) {
        try {
          const newSummary = await updatePostSummary(id, descriptionVal);
          if (newSummary) {
            updatedPostWithSummary.summary = newSummary;
          }
        } catch (summaryError) {
          // If summary generation fails, do not fail the entire update
          console.error('Error generating summary:', summaryError);
          setGeneralError('Unable to generate summary, but your post was updated successfully.');
        }
      }

      // Update local context
      updateSinglePost(updatedPostWithSummary);

      // All done, finalize
      setIsSubmitting(false);
      navigate(`/clipsposts/${updatedPost.id}`);
    } catch (error) {
      console.error('Error updating post:', error);
      setIsSubmitting(false);
    }
  };

  // Close
  const handleClose = () => {
    navigate(-1);
  };

  // If loading user, show a spinner
  if (loading) {
    return (
      <div className="form-container" style={{ marginTop: '150px', color: 'black' }}>
        Loading user...
      </div>
    );
  }

  // If no post data loaded yet
  if (!post) {
    return (
      <div className="form-container" style={{ marginTop: '150px', color: 'black' }}>
        Loading...
      </div>
    );
  }

  // ~~~~~ Render ~~~~~
  return (
    <div className="form-container" style={{ marginTop: '150px', backgroundColor: 'white' }}>
      <CloseButton onClick={handleClose} />
      <div className="form-header">
        <h2 className="form-title" style={{ color: 'black' }}>Edit Post</h2>
      </div>

      <form className="post-form" onSubmit={handleSubmit}>
        {/* Post Title */}
        <div className="form-group">
          <label style={{ color: 'black' }}>What Are You Sharing With Us?</label>
          <input
            type="text"
            name="nickname"
            className={`form-input ${showErrors && fieldErrors.nickname ? 'error' : ''}`}
            placeholder="The Name of what You're Sharing. Ideas are Welcome Too!"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
          {showErrors && fieldErrors.nickname && (
            <p className="error-message">{fieldErrors.nickname}</p>
          )}
        </div>

        {/* Description */}
        <div className="form-group">
          <label
            style={{ color: 'black' }}
            title="Briefly tell us the story behind what you're sharing"
          >
            Tell Us What It's All About
          </label>
          <textarea
            name="description"
            className={`form-input ${showErrors && fieldErrors.description ? 'error' : ''}`}
            rows="4"
            placeholder="Briefly describe what you're sharing (up to 40 words)"
            value={description}
            onChange={handleDescriptionChange}
          />
          <p className={`word-count ${wordCount > 40 ? 'word-count-exceeded' : ''}`}>
            {wordCount} / 40 words
          </p>
          {showErrors && fieldErrors.description && (
            <p className="error-message">{fieldErrors.description}</p>
          )}
          <p
            style={{
              color: '#666',
              fontSize: '0.9rem',
              marginTop: '8px',
              marginBottom: '16px',
              fontStyle: 'italic'
            }}
          >
            Your Post Description will also be used by AI to Promote your Post across HyroClipse.
          </p>
        </div>

        {/* Categories */}
        <div className="form-group category-group">
          <label style={{ color: 'black' }}>Select a Category</label>
          <div className="form-options">
            {sortedCategories.map((category) => (
              <div key={category}>
                <input
                  type="checkbox"
                  id={category.toLowerCase()}
                  name="category"
                  value={category}
                  checked={categories.includes(category)}
                  onChange={handleCategoryChange}
                />
                <label htmlFor={category.toLowerCase()}>{category}</label>
              </div>
            ))}
          </div>
          {showErrors && fieldErrors.categories && (
            <p className="error-message">{fieldErrors.categories}</p>
          )}
        </div>

        {/* Goals */}
        <div className="form-group goals-group">
          <label style={{ color: 'black' }}>Select Your Goal(s)</label>
          <div className="form-options">
            {sortedGoals.map((goal) => (
              <div key={goal}>
                <input
                  type="checkbox"
                  id={goal.toLowerCase().replace(/\s+/g, '-')}
                  name="goals"
                  value={goal}
                  checked={goals.includes(goal)}
                  onChange={(e) => {
                    const { value, checked } = e.target;
                    if (checked) {
                      setGoals((prev) => [...prev, value]);
                    } else {
                      setGoals((prev) => prev.filter((g) => g !== value));
                    }
                  }}
                />
                <label htmlFor={goal.toLowerCase().replace(/\s+/g, '-')}>{goal}</label>
              </div>
            ))}
          </div>
          {showErrors && fieldErrors.goals && (
            <p className="error-message">{fieldErrors.goals}</p>
          )}
        </div>

        {/* Link */}
        <div className="form-group">
          <label style={{ color: 'black' }}>Add a URL Link (Optional)</label>
          <input
            type="text"
            name="link"
            className={`form-input ${showErrors && fieldErrors.link ? 'error' : ''}`}
            placeholder="e.g., example.com"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          {showErrors && fieldErrors.link && (
            <p className="error-message">{fieldErrors.link}</p>
          )}
        </div>

        {/* Socials */}
        <div className="form-group">
          <label style={{ color: 'black' }}>Share Your Socials (Optional)</label>
          <div className="social-inputs">
            {/* Instagram */}
            <div className="social-input">
              <FaInstagram className="social-icon instagram" />
              <input
                type="text"
                name="instagram"
                className="form-input"
                placeholder="Instagram Username"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
            </div>
            {/* Twitter */}
            <div className="social-input">
              <FaTwitter className="social-icon twitter" />
              <input
                type="text"
                name="twitter"
                className="form-input"
                placeholder="Twitter/X Username"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </div>
            {/* TikTok */}
            <div className="social-input">
              <FaTiktok className="social-icon tiktok" />
              <input
                type="text"
                name="tiktok"
                className="form-input"
                placeholder="TikTok Username"
                value={tiktok}
                onChange={(e) => setTiktok(e.target.value)}
              />
            </div>
            {/* YouTube */}
            <div className="social-input">
              <FaYoutube className="social-icon youtube" />
              <input
                type="text"
                name="youtube"
                className="form-input"
                placeholder="YouTube Channel Handle"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
              />
            </div>
            {/* Facebook */}
            <div className="social-input">
              <FaFacebook className="social-icon facebook" />
              <input
                type="text"
                name="facebook"
                className="form-input"
                placeholder="Facebook Username"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Email */}
        <div className="form-group">
          <label style={{ color: 'black' }}>Add Your Contact Email (Optional)</label>
          <input
            type="email"
            name="email"
            className={`form-input ${showErrors && fieldErrors.email ? 'error' : ''}`}
            placeholder="e.g., your@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {showErrors && fieldErrors.email && (
            <p className="error-message">{fieldErrors.email}</p>
          )}
        </div>

        {/* Location */}
        <div className="form-group location-group">
          <label style={{ color: 'black' }}>Where Are You Based?</label>
          <div className="form-options">
            {[
              'North America',
              'Europe',
              'Africa',
              'Middle East',
              'Asia',
              'Central America',
              'South America',
              'Australia'
            ].map((loc) => (
              <div key={loc}>
                <input
                  type="radio"
                  id={loc.toLowerCase().replace(' ', '')}
                  name="location"
                  value={loc}
                  checked={userLocation === loc}
                  onChange={(e) => setUserLocation(e.target.value)}
                />
                <label htmlFor={loc.toLowerCase().replace(' ', '')}>{loc}</label>
              </div>
            ))}
          </div>
          {showErrors && fieldErrors.location && (
            <p className="error-message">{fieldErrors.location}</p>
          )}
        </div>

        {/* City */}
        <div className="form-group">
          <label style={{ color: 'black' }}>City</label>
          <input
            type="text"
            name="city"
            className={`form-input ${showErrors && fieldErrors.city ? 'error' : ''}`}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          {showErrors && fieldErrors.city && (
            <p className="error-message">{fieldErrors.city}</p>
          )}
        </div>

        {/* State */}
        <div className="form-group">
          <label style={{ color: 'black' }}>State (Optional)</label>
          <input
            type="text"
            name="state"
            className="form-input"
            value={stateVal}
            onChange={(e) => setStateVal(e.target.value)}
          />
        </div>

        {/* Country */}
        <div className="form-group">
          <label style={{ color: 'black' }}>Country</label>
          <input
            type="text"
            name="country"
            className={`form-input ${showErrors && fieldErrors.country ? 'error' : ''}`}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          {showErrors && fieldErrors.country && (
            <p className="error-message">{fieldErrors.country}</p>
          )}
        </div>

        {/* Main Image Dropzone */}
        <MyDropzone
          onImageUpload={handleImageUpload}
          setIsImageProcessing={setIsImageProcessing}
          setProgress={setProgress}
          setUploadError={setUploadError}
          initialImage={postImagePreview}
          onCropperOpen={setIsCropperOpen}
        />
        {uploadError && <p className="error-message">{uploadError}</p>}
        {isImageProcessing && (
          <div className="progress-bar-container">
            <progress value={progress} max="100"></progress>
          </div>
        )}

        {/* Your Name (Optional) */}
        <div className="form-group">
          <label style={{ color: 'black' }}>Your Name (Optional)</label>
          <input
            type="text"
            name="userName"
            className={`form-input ${showErrors && fieldErrors.userName ? 'error' : ''}`}
            placeholder="Enter your name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          {showErrors && fieldErrors.userName && (
            <p className="error-message">{fieldErrors.userName}</p>
          )}
        </div>

        {/* Bio (Optional) */}
        <div className="form-group">
          <label style={{ color: 'black' }}>
            Brief Bio (Optional)
          </label>
          <textarea
            name="bio"
            className={`form-input ${showErrors && bioWordCount > 40 ? 'error' : ''}`}
            rows="4"
            placeholder="Enter a brief bio (max 40 words)"
            value={bio}
            onChange={handleBioChange}
          />
          <p className={`word-count ${bioWordCount > 40 ? 'word-count-exceeded' : ''}`}>
            {bioWordCount} / 40 words
          </p>
          {showErrors && bioWordCount > 40 && (
            <p className="error-message">Bio cannot exceed 40 words.</p>
          )}
          {showErrors && fieldErrors.bio && (
            <p className="error-message">{fieldErrors.bio}</p>
          )}
        </div>

        {/* Profile Image Dropzone (UPDATED SECTION) */}
        <h3 style={{ color: 'black', marginBottom: '8px', textAlign: 'center' }}>
          Profile Image
        </h3>
        <ProfileImageDropzone
          onImageUpload={handleProfileImageUpload}
          setIsImageProcessing={setProfileImageProcessing}
          setProgress={setProfileImageProgress}
          setUploadError={setProfileImageError}
          initialImage={profileImagePreview}
          onCropperOpen={setIsProfileCropperOpen}
        />
        {profileImageError && <p className="error-message">{profileImageError}</p>}
        {profileImageProcessing && (
          <div className="progress-bar-container">
            <progress value={profileImageProgress} max="100"></progress>
          </div>
        )}
        <p
          style={{
            marginTop: '16px',
            fontSize: '0.95rem',
            color: '#555',
            fontStyle: 'italic',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          Add a Photo of You to Boost Post Views!
        </p>

        {/* ~~~ The top-level general error message ~~~ */}
        {generalError && (
          <p className="error-message" style={{ textAlign: 'center', marginTop: '20px' }}>
            {generalError}
          </p>
        )}

        {/* Hide buttons if either cropper is open */}
        {(!isCropperOpen && !isProfileCropperOpen) && (
          <div
            className="form-buttons-container"
            style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}
          >
            <button
              type="button"
              className="form-button cancel-button"
              onClick={() => navigate(`/clipsposts/${id}`)}
              style={{
                backgroundColor: '#6c757d',
                color: 'white',
                flex: '1',
                maxWidth: '200px'
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="form-button"
              disabled={isImageProcessing || isSubmitting}
              style={{ flex: '1', maxWidth: '200px' }}
            >
              {isSubmitting ? 'Updating...' : 'Update Post'}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ClipsEditPostForm;


