// Sidebar.js

import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // <-- useLocation
import './Sidebar.css';
import CloseButton from './CloseButton';
import { useAuth } from '../contexts/AuthContext';
import ActiveItemContext from '../contexts/ActiveItemContext';
import { saveDataToDB } from '../utils/dbUtilities';

import { 
  IoHomeOutline, 
  IoCompassOutline, 
  IoAddCircleOutline, 
  IoLogOutOutline, 
  IoPersonAddOutline, 
  IoMailOutline, 
  IoSettingsOutline,
  IoHeartOutline,
  IoPersonOutline
} from 'react-icons/io5';
import { MdFiberNew } from 'react-icons/md';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsFillPersonVcardFill } from 'react-icons/bs';
import { FaThumbtack } from 'react-icons/fa6'; // Updated: Using FaThumbtack

const Sidebar = ({ isVisible, setIsSidebarVisible }) => {
  const navigate = useNavigate();
  const location = useLocation(); // for checking current pathname
  const { isLoggedIn, currentUser: authUser, logout } = useAuth();
  const { activeItem, setActiveItem } = useContext(ActiveItemContext);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1180);

  useEffect(() => {
    const handleResize = () => {
      const isTabletSize = window.innerWidth <= 1180;
      setIsDesktop(!isTabletSize);
      setIsSidebarVisible(!isTabletSize);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsSidebarVisible]);

  useEffect(() => {
    if (authUser && !authUser.displayName && authUser.reload) {
      authUser.reload();
    }
  }, [authUser]);

  const username = isLoggedIn 
    ? (authUser?.username || authUser?.displayName || authUser?.email?.split('@')[0]) 
    : 'HyroClipse';

  const handleLogoutClick = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  // Left unchanged:
  const baseMenuItems = [
    { id: 'Home', icon: <IoHomeOutline className="sidebar-icon" />, link: '/', hideOnMobile: true },
    { id: 'Post', icon: <IoAddCircleOutline className="sidebar-icon" />, link: '/clipspostform' },
    { id: 'New Posts', icon: <MdFiberNew className="sidebar-icon" />, link: '/newposts', hideOnLargeScreen: true, hideOnMobile: true },
    { id: 'Explore', icon: <IoCompassOutline className="sidebar-icon" />, link: '/explore', hideOnLargeScreen: true, hideOnMobile: true },
    { id: 'Discover People', icon: <BsFillPersonVcardFill className="sidebar-icon" />, link: '/bio', hideOnLargeScreen: true },
    { id: 'Bulletin', icon: <FaThumbtack className="sidebar-icon" />, link: '/bulletin' },
    { id: 'Info', icon: <AiOutlineInfoCircle className="sidebar-icon" />, link: '/info' },
  ];

  const loggedInMenuItems = [
    { id: 'Likes', icon: <IoHeartOutline className="sidebar-icon" />, link: '/clipslikes', hideOnLargeScreen: true },
    { id: 'My Posts', icon: <IoPersonOutline className="sidebar-icon" />, link: '/clipsmyposts', hideOnLargeScreen: true },
    { id: 'Messages', icon: <IoMailOutline className="sidebar-icon" />, link: '/messages', hideOnLargeScreen: true },
    { id: 'Logout', icon: <IoLogOutOutline className="sidebar-icon" />, onClick: handleLogoutClick }
  ];

  const loggedOutMenuItems = [
    { id: 'Sign Up', icon: <IoPersonAddOutline className="sidebar-icon" />, link: '/signup' },
    { id: 'Login', icon: <IoLogOutOutline className="sidebar-icon" />, link: '/login' }
  ];

  const menuItems = isLoggedIn 
    ? [...baseMenuItems, ...loggedInMenuItems] 
    : [...baseMenuItems, ...loggedOutMenuItems];

  const adminMenuItem = { 
    id: 'Admin Dashboard', 
    icon: <IoSettingsOutline className="sidebar-icon" />, 
    link: '/clips-admin-dashboard'
  };

  // CHANGED: scroll to top if user re-clicks a link that matches the current pathname
  const handleItemClick = async (item) => {
    // If user is on a smaller screen, close the sidebar
    if (window.innerWidth <= 1180) {
      setIsSidebarVisible(false);
      document.body.classList.remove('sidebar-open');
    }

    setActiveItem(item.id);

    // If the item has a link
    if (item.link) {
      // Check if we are currently on that path
      if (location.pathname === item.link) {
        // Already on that page, so just scroll
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        // Different path, so navigate
        navigate(item.link);
      }
    } 
    // Or if the item has an onClick (e.g. logout)
    else if (item.onClick) {
      item.onClick();
    }

    // Save active item if user is authed
    if (authUser) {
      try {
        await saveDataToDB('userSettings', { activeItem: item.id }, authUser.uid);
      } catch (error) {
        console.error('Error saving active item:', error);
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isVisible]);

  const SidebarItem = ({ item }) => {
    const isNewPostsItem = item.id === 'New Posts';
    const shouldHideOnLargeScreen = item.hideOnLargeScreen && window.innerWidth >= 1180;
    const shouldHideOnMobile = item.hideOnMobile && window.innerWidth <= 1180;

    if (shouldHideOnLargeScreen || shouldHideOnMobile) {
      return null;
    }

    return (
      <li 
        className={`sidebar-item ${isNewPostsItem ? 'sidebar-item--new-posts' : ''} 
          ${activeItem === item.id ? 'active' : ''}`}
      >
        <Link 
          to={item.link || '#'} 
          className="sidebar-link" 
          onClick={(e) => {
            e.preventDefault();
            handleItemClick(item);
          }}
        >
          <div className="sidebar-link-content">
            <div className="sidebar-icon-wrapper">{item.icon}</div>
            <span className="sidebar-text">{item.id}</span>
          </div>
        </Link>
      </li>
    );
  };

  return (
    <div className={`sidebar ${isVisible ? 'open' : ''}`}>
      <div className="mobile-header">
        <div className="profile-name-container">
          <h3 className="profile-name">{username}</h3>
        </div>
        <button 
          className="mobile-close-button" 
          onClick={() => {
            setIsSidebarVisible(false);
            document.body.classList.remove('sidebar-open');
          }}
        >
          ✕
        </button>
      </div>
      <div className="profile-section">
        {isDesktop && (
          <Link 
            to="/" 
            className="profile-link" 
            style={{ textDecoration: 'none', color: 'inherit' }} 
            onClick={(e) => {
              e.preventDefault();
              if (window.innerWidth <= 1180) {
                setIsSidebarVisible(false);
              }
              // If already on '/', scroll up, else navigate
              if (location.pathname === '/') {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              } else {
                navigate('/');
              }
            }}
          >
            <img 
              src={`${process.env.PUBLIC_URL}/upscaled_hyroclipse_logo.png`} 
              alt="HyroClips Logo" 
              className="profile-pic" 
            />
            <div className="profile-name-container">
              <h3 className="profile-name">{username}</h3>
            </div>
          </Link>
        )}
      </div>
      <nav>
        <ul className="sidebar-menu">
          {menuItems.map(item => <SidebarItem key={item.id} item={item} />)}
          {authUser?.role === 'admin' && <SidebarItem item={adminMenuItem} />}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
